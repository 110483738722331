import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import { RoleActionPermissionsFragmentDoc } from '../components/modals/NewRoleModal.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', Me?: { __typename?: 'Me', user: { __typename?: 'Employee', id: number, userType: Types.UserType, roles: Array<{ __typename?: 'Role', id: number, roleLevel?: number | null }> } } | null, Roles: { __typename?: 'Roles', objects: Array<{ __typename?: 'Role', id: number, name: string, roleLevel?: number | null, userCount: number, roleGroupMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, roleGroup: { __typename?: 'RoleGroup', id: string, name: string } }>, roleActionPermissions?: { __typename?: 'RoleActionPermissions', id: string, roleUserType: Types.UserType, requireClockInConfirmation: boolean, actionPermissions: { __typename?: 'ActionPermissionSet', id: string, changeOrgSettings?: boolean | null, manageOrgIntegrations?: boolean | null, manageBilling?: boolean | null, inviteOthers?: boolean | null, createContent?: boolean | null, manageLibrary?: boolean | null, assignContent?: boolean | null, unassignContent?: boolean | null, updateAutomations?: boolean | null, verifySkills?: boolean | null, viewReporting?: boolean | null, sendMessages?: boolean | null, editTrainees?: boolean | null, deactivateTrainees?: boolean | null, managePremiumContent?: boolean | null, promoteTrainees?: boolean | null, markAsComplete?: boolean | null } } | null, directReports: Array<{ __typename?: 'Role', id: number, name: string }> }> } };

export type RoleThinFragment = { __typename?: 'Role', id: number, name: string, roleLevel?: number | null, userCount: number, roleGroupMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, roleGroup: { __typename?: 'RoleGroup', id: string, name: string } }>, roleActionPermissions?: { __typename?: 'RoleActionPermissions', id: string, roleUserType: Types.UserType, requireClockInConfirmation: boolean, actionPermissions: { __typename?: 'ActionPermissionSet', id: string, changeOrgSettings?: boolean | null, manageOrgIntegrations?: boolean | null, manageBilling?: boolean | null, inviteOthers?: boolean | null, createContent?: boolean | null, manageLibrary?: boolean | null, assignContent?: boolean | null, unassignContent?: boolean | null, updateAutomations?: boolean | null, verifySkills?: boolean | null, viewReporting?: boolean | null, sendMessages?: boolean | null, editTrainees?: boolean | null, deactivateTrainees?: boolean | null, managePremiumContent?: boolean | null, promoteTrainees?: boolean | null, markAsComplete?: boolean | null } } | null, directReports: Array<{ __typename?: 'Role', id: number, name: string }> };

export const RoleThinFragmentDoc = gql`
    fragment RoleThin on Role {
  id
  name
  roleLevel
  userCount
  roleGroupMemberships {
    id
    roleGroup {
      id
      name
    }
  }
  roleActionPermissions {
    ...RoleActionPermissions
  }
  directReports {
    id
    name
  }
}
    ${RoleActionPermissionsFragmentDoc}`;
export const GetRolesDocument = gql`
    query GetRoles {
  Me {
    user {
      id
      userType
      roles {
        id
        roleLevel
      }
    }
  }
  Roles {
    objects {
      ...RoleThin
    }
  }
}
    ${RoleThinFragmentDoc}`;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;