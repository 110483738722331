import gql from "graphql-tag";
import { useMemo } from "react";
import {
  LocationThinFragment,
  useGetLocationsQuery,
} from "./useLocations.generated";

type UseLocations = {
  locations: LocationThinFragment[];
  loading: boolean;
};

const useLocations = (): UseLocations => {
  const { data, previousData, loading } = useGetLocationsQuery();
  const locations = useMemo(() => {
    const d = data || previousData;
    return d?.Locations.objects || [];
  }, [data, previousData]);

  return {
    locations,
    loading,
  };
};

gql`
  query GetLocations {
    Locations {
      objects {
        ...LocationThin
      }
    }
  }
  fragment LocationThin on Location {
    id
    name
  }
`;

export default useLocations;
