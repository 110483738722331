import { LocationGroupType, UserSelectionInput } from "@src/types.generated";
import { UserSelectionFilterType } from "./SelectUsersModal";
import { camelCase, startCase } from "lodash";
import { ContentType } from "@src/deprecatedDesignSystem/components/ContentAvatar";
import {
  AutomationItem,
  AutomationContentType,
} from "@src/components/sidebars/shared/constants";

export const isLocationGroupType = (
  filter: UserSelectionFilterType,
): boolean => {
  return (
    filter === LocationGroupType.Region ||
    filter === LocationGroupType.Brand ||
    filter === LocationGroupType.Franchise
  );
};

export const toCamelCase = (text: string): string => {
  return startCase(camelCase(text));
};

export const getEmptyUserSelectionInput = (): UserSelectionInput => {
  return {
    allLocations: false,
    locationGroupIds: [],
    locationIds: [],
    roleIds: [],
    allManagers: false,
    allAdmins: false,
    allTrainees: false,
  };
};

export const getContentIdFromAutomationItem = (
  automationItem: AutomationItem,
): string | number => {
  if (automationItem.automation.libraryItem?.path?.id) {
    return automationItem.automation.libraryItem?.path?.id;
  } else if (automationItem.automation.libraryItem?.course?.id) {
    return automationItem.automation.libraryItem?.course?.id;
  } else if (automationItem.automation.libraryItem?.trainingResource?.id) {
    return automationItem.automation.libraryItem?.trainingResource?.id;
  } else {
    return -1;
  }
};

export const getAutomationItemContentType = (
  automationItemType: AutomationContentType,
): ContentType => {
  if (automationItemType === "Course") {
    return "Course";
  } else if (automationItemType === "TrainingResource") {
    return "Resource";
  } else {
    return "Module";
  }
};

export const formatSentenceList = (
  list: string[],
  conjunction: "and" | "or",
): string => {
  if (list.length === 0) {
    return "";
  } else if (list.length === 1) {
    return list[0];
  } else {
    const lastItem = list.pop();
    return `${list.join(", ")} ${conjunction} ${lastItem}`;
  }
};
