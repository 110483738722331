import { RefObject, useEffect, useState } from "react";

type Return = {
  x: number;
  y: number;
  width: number;
  height: number;
};

const useGetRefPositionAndDimensions = (
  ref: RefObject<HTMLDivElement>,
): Return => {
  const [val, setVal] = useState<Return>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const setGetBoundingClientRect = () => {
    const boundingClient = ref?.current?.getBoundingClientRect();
    const newX = boundingClient?.left || 0;
    const newY = boundingClient?.top || 0;
    const newWidth = boundingClient?.width || 0;
    const newHeight = boundingClient?.height || 0;
    if (
      val.x !== newX ||
      val.y !== newY ||
      val.width !== newWidth ||
      val.height !== newHeight
    ) {
      setVal({
        x: newX,
        y: newY,
        width: newWidth,
        height: newHeight,
      });
    }
  };
  setTimeout(() => {
    setGetBoundingClientRect();
  }, 0);
  useEffect(() => {
    window.addEventListener("resize", setGetBoundingClientRect);
    return () => {
      window.removeEventListener("resize", setGetBoundingClientRect);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return val;
};

export default useGetRefPositionAndDimensions;
