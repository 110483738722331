import { gql } from "@apollo/client";
import {
  UserActionPermissionsFragment,
  useUserActionPermissionsQuery,
} from "@contexts/ActionPermissionsContext.generated";
import useUser from "@hooks/useUser";
import { UserActionPermissionValue } from "@src/types.generated";
import { isUserActionPermissionValue } from "@utils/actionPermissions";
import { FC, ReactNode, createContext, useMemo } from "react";

export type UserActionPermissionBooleans = {
  [key in keyof Omit<UserActionPermissionsFragment, "__typename">]?: boolean;
};

export interface ActionPermissionsContextState {
  userActionPermissions?: UserActionPermissionBooleans;
  userActionPermissionValues?: UserActionPermissionsFragment;
  loading: boolean;
}
export const ActionPermissionsContext =
  createContext<ActionPermissionsContextState>({
    userActionPermissions: undefined,
    userActionPermissionValues: undefined,
    loading: true,
  });

type Props = {
  children: ReactNode;
};

const ActionPermissionsContextProvider: FC<Props> = ({ children }) => {
  const { user } = useUser();
  const { data, loading } = useUserActionPermissionsQuery({
    skip: !user?.id,
    variables: {
      userId: Number(user?.id),
    },
  });
  const userActionPermissionValues = useMemo(
    () => data?.UserActionPermissions,
    [data?.UserActionPermissions],
  );
  const userActionPermissions = useMemo(() => {
    if (!userActionPermissionValues) return undefined;
    return Object.fromEntries(
      Object.entries(userActionPermissionValues)
        .filter(([, value]) => isUserActionPermissionValue(value))
        .map(([key, value]) => [
          key,
          (value as UserActionPermissionValue).hasPermission,
        ]),
    );
  }, [userActionPermissionValues]);
  const value = useMemo(
    () => ({
      userActionPermissions,
      userActionPermissionValues,
      loading: loading && !userActionPermissionValues,
    }),
    [userActionPermissions, userActionPermissionValues, loading],
  );
  return (
    <ActionPermissionsContext.Provider value={value}>
      {children}
    </ActionPermissionsContext.Provider>
  );
};

gql`
  fragment UserActionPermissionValue on UserActionPermissionValue {
    hasPermission
    reason
  }
  fragment UserActionPermissions on UserActionPermissions {
    changeOrgSettings {
      ...UserActionPermissionValue
    }
    manageOrgIntegrations {
      ...UserActionPermissionValue
    }
    manageBilling {
      ...UserActionPermissionValue
    }
    inviteOthers {
      ...UserActionPermissionValue
    }
    createContent {
      ...UserActionPermissionValue
    }
    manageLibrary {
      ...UserActionPermissionValue
    }
    assignContent {
      ...UserActionPermissionValue
    }
    unassignContent {
      ...UserActionPermissionValue
    }
    updateAutomations {
      ...UserActionPermissionValue
    }
    verifySkills {
      ...UserActionPermissionValue
    }
    viewReporting {
      ...UserActionPermissionValue
    }
    sendMessages {
      ...UserActionPermissionValue
    }
    editTrainees {
      ...UserActionPermissionValue
    }
    deactivateTrainees {
      ...UserActionPermissionValue
    }
    managePremiumContent {
      ...UserActionPermissionValue
    }
    promoteTrainees {
      ...UserActionPermissionValue
    }
    markAsComplete {
      ...UserActionPermissionValue
    }
  }

  query UserActionPermissions($userId: Int!) {
    UserActionPermissions(userId: $userId) {
      ...UserActionPermissions
    }
  }
`;

export default ActionPermissionsContextProvider;
