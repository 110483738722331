import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { CSSProperties, FC } from "react";
import { isValid, parseISO, format } from "date-fns";

export type DateTimeInputType = "datetime-local" | "date";

type Props = {
  value?: Date | null | undefined;
  required?: boolean;
  onChange: (val: Date) => void;
  disabled?: boolean;
  type?: DateTimeInputType;
  min?: string;
  max?: string;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
};

const TYPE_TO_FORMAT: { [key: string]: string } = {
  "datetime-local": "yyyy-MM-dd'T'HH:mm",
  date: "yyyy-MM-dd",
};

const DateTimeField: FC<Props> = ({
  value,
  required,
  onChange,
  disabled = false,
  type = "datetime-local",
  min = "",
  max = "",
  style,
  styleDeclaration,
}) => {
  return (
    <input
      type={type}
      required={required}
      min={min}
      max={max}
      disabled={disabled}
      className={css(styles.container, styleDeclaration)}
      value={fmtDateForInput(type, value)}
      onChange={(event) => {
        const dateStr = event?.target?.value;
        const newDate = parseISO(dateStr);
        if (newDate && isValid(newDate)) {
          onChange(newDate);
        }
      }}
      style={style}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    display: "block",
    height: 32,
    padding: "0 8px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    color: deprecatedTones.black,
    borderRadius: 8,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    backgroundColor: deprecatedTones.white,
    outline: "none",
    cursor: "text",
    ":hover": {
      border: `1px solid ${deprecatedTones.blue9}`,
    },
    ":focus": {
      border: `2px solid ${deprecatedTones.blue5}`,
    },
    ":disabled": {
      border: `1px solid ${deprecatedTones.gray5Alpha}`,
      backgroundColor: deprecatedTones.gray4Alpha,
    },
  },
});

export default DateTimeField;

export function fmtDateForInput(
  type: string,
  date?: Date | null | undefined,
): string {
  return date ? format(date, TYPE_TO_FORMAT[type]) : "";
}
