import { CSSProperties, FC, ReactNode } from "react";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import { deprecatedTones } from "../styles/deprecatedColors";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";

type Props = {
  icon: DeprecatedIconType;
  label: string;
  children: ReactNode;
  containerStyle?: CSSProperties;
  containerStyleDeclaration?: StyleDeclaration;
  labelStyle?: CSSProperties;
  labelStyleDeclaration?: StyleDeclaration;
  valueStyle?: CSSProperties;
  valueStyleDeclaration?: StyleDeclaration;
};

const InlineEditField: FC<Props> = ({
  icon,
  label,
  children,
  containerStyle,
  containerStyleDeclaration,
  labelStyle,
  labelStyleDeclaration,
  valueStyle,
  valueStyleDeclaration,
}) => {
  return (
    <div
      className={css(styles.container, containerStyleDeclaration)}
      style={containerStyle}
    >
      <div
        className={css(styles.labelContainer, labelStyleDeclaration)}
        style={labelStyle}
      >
        <DeprecatedIcon
          type={icon}
          color={deprecatedTones.gray7}
          style={{ marginRight: 8 }}
        />
        <Text
          type="P2"
          fontWeight="SemiBold"
          multiline
          style={{ marginTop: 2 }}
        >
          {label}
        </Text>
      </div>
      <div
        className={css(styles.valueContainer, valueStyleDeclaration)}
        style={valueStyle}
      >
        {children}
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    marginRight: 8,
  },
  labelContainer: {
    display: "flex",
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 16,
  },
  valueContainer: {
    position: "relative",
  },
});

export default InlineEditField;
