import { ActionPermissionSetFragment } from "@components/modals/NewRoleModal.generated";
import {
  UserActionPermissionValueFragment,
  UserActionPermissionsFragment,
} from "@contexts/ActionPermissionsContext.generated";
import {
  ActionPermissionSetInput,
  OptionalBooleanValue,
  UserType,
} from "@src/types.generated";

export type UserActionPermissionsKey = keyof Omit<
  UserActionPermissionsFragment,
  "__typename"
>;

export const isUserActionPermissionValue = (
  value: UserActionPermissionValueFragment | string,
): value is UserActionPermissionValueFragment => {
  return (
    (value as UserActionPermissionValueFragment).__typename ===
    "UserActionPermissionValue"
  );
};

export type PermissionDetails = {
  name: string;
  description?: string;
};

export const USER_ACTION_PERMISSIONS_NAME_AND_DESCRIPTION: Record<
  UserActionPermissionsKey,
  PermissionDetails
> = {
  changeOrgSettings: {
    name: "Change Org Settings",
    description: "Manage roles, locations, brand, change user type, etc",
  },
  editTrainees: {
    name: "Edit Trainees",
    description: "Edit someone's name, roles, locations, etc",
  },
  deactivateTrainees: {
    name: "Deactivate Trainees",
    description: "Deactivate or reactivate a user",
  },
  manageOrgIntegrations: {
    name: "Manage Org Integrations",
    description: "Manage integrations like HRIS",
  },
  manageBilling: {
    name: "Manage Billing",
    description: "Configure payment method / view billing history",
  },
  inviteOthers: {
    name: "Invite Others",
    description: "Invite new users to the org",
  },
  createContent: {
    name: "Create Content",
    description: "Create courses, modules, files, or check-ins",
  },
  manageLibrary: {
    name: "Manage Library",
    description: "Manage tags, archive/unarchive content",
  },
  assignContent: {
    name: "Assign Content",
    description: "Manually assign any non-premium content",
  },
  unassignContent: {
    name: "Unassign Content",
    description: "Manually unassign any non-premium content",
  },
  updateAutomations: {
    name: "Update Automations and Library Access",
    description: "Manage content automations",
  },
  verifySkills: {
    name: "Complete all check-ins",
    description: "Can complete any check-ins at their locations",
  },
  viewReporting: {
    name: "View Reporting",
    description: "View and export reporting data",
  },
  sendMessages: {
    name: "Send Messages",
    description: "Send messages in the dashboard and mobile app",
  },
  managePremiumContent: {
    name: "Manage Premium Content",
    description: "Manage content like Sexual Harassment Prevention Training",
  },
  promoteTrainees: {
    name: "Promote Trainees",
    description: "Promote Trainees to Manager  in Opus",
  },
  markAsComplete: {
    name: "Mark as Complete",
    description: "Mark any content as complete",
  },
};

const PERMISSION_KEYS_WITH_NAME = Object.keys(
  USER_ACTION_PERMISSIONS_NAME_AND_DESCRIPTION,
) as UserActionPermissionsKey[];

type RequiredAndOptionalRolePermissions = {
  required: UserActionPermissionsKey[];
  optionalDefaultChecked: UserActionPermissionsKey[];
  optionalDefaultUnchecked?: UserActionPermissionsKey[];
};

export const USER_TYPE_TO_PERMISSIONS: Record<
  UserType,
  RequiredAndOptionalRolePermissions
> = {
  [UserType.Admin]: {
    required: ["editTrainees", "deactivateTrainees"],
    optionalDefaultChecked: [
      "changeOrgSettings",
      "manageOrgIntegrations",
      "manageBilling",
      "inviteOthers",
      "createContent",
      "manageLibrary",
      "assignContent",
      "unassignContent",
      "updateAutomations",
      "verifySkills",
      "viewReporting",
      "sendMessages",
      "managePremiumContent",
      "promoteTrainees",
      "markAsComplete",
    ],
  },
  [UserType.Manager]: {
    required: ["inviteOthers"],
    optionalDefaultChecked: [
      "editTrainees",
      "promoteTrainees",
      "deactivateTrainees",
      "createContent",
      "assignContent",
      "unassignContent",
      "verifySkills",
      "sendMessages",
      "manageLibrary",
      "viewReporting",
    ],
    optionalDefaultUnchecked: ["markAsComplete"],
  },
  [UserType.Employee]: {
    required: [],
    optionalDefaultChecked: ["verifySkills"],
  },
};

export const createActionPermissionSetInputWithDefault = (
  defaultValue: boolean | undefined,
): ActionPermissionSetInput => {
  const input = PERMISSION_KEYS_WITH_NAME.reduce(
    (
      acc: Partial<{ [key in UserActionPermissionsKey]: OptionalBooleanValue }>,
      key: UserActionPermissionsKey,
    ) => {
      acc[key] = {
        value: defaultValue,
      };
      return acc;
    },
    {},
  ) as { [key in UserActionPermissionsKey]: OptionalBooleanValue };
  return input;
};

export const createDefaultActionPermissionSetInputForUserType = (
  userType?: UserType,
): ActionPermissionSetInput => {
  const input = createActionPermissionSetInputWithDefault(false);
  if (!userType) {
    return input;
  }
  const { required, optionalDefaultChecked, optionalDefaultUnchecked } =
    USER_TYPE_TO_PERMISSIONS[userType];
  const checkedKeys = [...required, ...optionalDefaultChecked];
  const uncheckedKeys = optionalDefaultUnchecked || [];
  const newInput = { ...input };
  if (userType === UserType.Employee) {
    return newInput;
  }
  checkedKeys.forEach((key) => {
    newInput[key] = {
      value: true,
    };
  });
  uncheckedKeys.forEach((key) => {
    newInput[key] = {
      value: false,
    };
  });
  return newInput;
};

export const actionPermissionsFragmentToInput = (
  actionPermissionSet?: ActionPermissionSetFragment,
): ActionPermissionSetInput => {
  const input = { ...createDefaultActionPermissionSetInputForUserType() };
  if (!actionPermissionSet) {
    return input;
  }
  Object.entries(actionPermissionSet).forEach(([key, value]) => {
    if (key in USER_ACTION_PERMISSIONS_NAME_AND_DESCRIPTION) {
      input[key as UserActionPermissionsKey] = {
        value: typeof value === "boolean" ? value : undefined,
      };
    }
  });
  return input as ActionPermissionSetInput;
};
