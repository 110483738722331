import useUserSelections from "./useUserSelections";
import { useMemo } from "react";
import { capitalizeFirstLetter, pluralize } from "@src/utils/strings";
import { LocationGroupType, UserSelectionInput } from "@src/types.generated";

type LocationGroupsLabel = Record<LocationGroupType, string | undefined>;
type Props = {
  input: UserSelectionInput;
  showUserTypeOptions?: boolean;
};
type Return = {
  hasSelection: boolean;
  nestLocationAndLocationGroupSelections: boolean;
  showRoles: boolean;
  rolesLabel?: string;
  showRoleGroups: boolean;
  roleGroupsLabel?: string;
  userTypesLabel?: string;
  showLocationGroups: boolean;
  locationGroupsLabel?: LocationGroupsLabel;
  showLocations: boolean;
  locationsLabel?: string;
  showUsers: boolean;
  usersLabel?: string;
};

const useUserSelectionPreviewState = (props: Props): Return => {
  const {
    selectedRolesCount,
    selectedRoleGroupsCount,
    selectedLocationsCount,
    selectedLocationGroupsCount,
    selectedUsersCount,
  } = useUserSelections(props.input);
  const oneUserTypeSelected = useMemo(() => {
    return (
      props.input.allTrainees ||
      props.input.allManagers ||
      props.input.allAdmins
    );
  }, [props.input.allTrainees, props.input.allManagers, props.input.allAdmins]);
  const hasSelection = useMemo(() => {
    if (props.showUserTypeOptions) {
      return (
        oneUserTypeSelected ||
        selectedRolesCount > 0 ||
        selectedRoleGroupsCount > 0 ||
        selectedLocationsCount > 0 ||
        selectedLocationGroupsCount.all > 0 ||
        selectedUsersCount > 0
      );
    } else {
      return (
        selectedRolesCount > 0 ||
        selectedRoleGroupsCount > 0 ||
        selectedLocationsCount > 0 ||
        selectedLocationGroupsCount.all > 0 ||
        selectedUsersCount > 0
      );
    }
  }, [
    oneUserTypeSelected,
    props.showUserTypeOptions,
    selectedLocationGroupsCount.all,
    selectedLocationsCount,
    selectedRoleGroupsCount,
    selectedRolesCount,
    selectedUsersCount,
  ]);
  const allUserTypesSelected = useMemo(() => {
    return (
      props.input.allTrainees &&
      props.input.allManagers &&
      props.input.allAdmins
    );
  }, [props.input.allAdmins, props.input.allManagers, props.input.allTrainees]);
  const anyLocationsOrLocationGroupsSelected = useMemo(() => {
    return (
      !!props.input.locationGroupIds?.length ||
      !!props.input.locationIds?.length
    );
  }, [props.input.locationGroupIds?.length, props.input.locationIds?.length]);

  const showRoles = useMemo(() => {
    if (allUserTypesSelected && anyLocationsOrLocationGroupsSelected) {
      return false;
    }
    if (props.showUserTypeOptions) {
      return oneUserTypeSelected || selectedRolesCount > 0;
    } else {
      return selectedRolesCount > 0;
    }
  }, [
    allUserTypesSelected,
    anyLocationsOrLocationGroupsSelected,
    oneUserTypeSelected,
    props.showUserTypeOptions,
    selectedRolesCount,
  ]);
  const showRoleGroups = useMemo(() => {
    if (allUserTypesSelected && anyLocationsOrLocationGroupsSelected) {
      return false;
    }
    if (props.showUserTypeOptions) {
      return oneUserTypeSelected || selectedRoleGroupsCount > 0;
    } else {
      return selectedRoleGroupsCount > 0;
    }
  }, [
    allUserTypesSelected,
    anyLocationsOrLocationGroupsSelected,
    oneUserTypeSelected,
    props.showUserTypeOptions,
    selectedRoleGroupsCount,
  ]);
  const nestLocationAndLocationGroupSelections = useMemo(() => {
    if (props.showUserTypeOptions) {
      return (
        !!(
          (selectedRolesCount + selectedRoleGroupsCount > 0 &&
            selectedLocationsCount + selectedLocationGroupsCount.all > 0) ||
          (oneUserTypeSelected &&
            selectedLocationsCount + selectedLocationGroupsCount.all > 0)
        ) &&
        (showRoles || showRoleGroups)
      );
    } else {
      return (
        !!(
          selectedRolesCount + selectedRoleGroupsCount > 0 &&
          selectedLocationsCount + selectedLocationGroupsCount.all > 0
        ) &&
        (showRoles || showRoleGroups)
      );
    }
  }, [
    oneUserTypeSelected,
    props.showUserTypeOptions,
    selectedLocationGroupsCount.all,
    selectedLocationsCount,
    selectedRoleGroupsCount,
    selectedRolesCount,
    showRoleGroups,
    showRoles,
  ]);

  const userTypesLabel = useMemo(() => {
    if (!showRoles) {
      return undefined;
    }
    if (props.showUserTypeOptions && oneUserTypeSelected) {
      return "All";
    } else {
      return undefined;
    }
  }, [oneUserTypeSelected, props.showUserTypeOptions, showRoles]);
  const rolesLabel = useMemo(() => {
    if (!showRoles || selectedRolesCount === 0) {
      return undefined;
    }
    const allUsersLabel = `All users with ${pluralize(
      "role",
      selectedRolesCount,
    )}`;
    const andUsersLabel = `and users with ${pluralize(
      "role",
      selectedRolesCount,
    )}`;
    if (props.showUserTypeOptions) {
      return !oneUserTypeSelected || selectedRoleGroupsCount > 0
        ? allUsersLabel
        : andUsersLabel;
    } else {
      return selectedRoleGroupsCount > 0 ? andUsersLabel : allUsersLabel;
    }
  }, [
    oneUserTypeSelected,
    props.showUserTypeOptions,
    selectedRoleGroupsCount,
    selectedRolesCount,
    showRoles,
  ]);
  const roleGroupsLabel = useMemo(() => {
    if (!showRoleGroups || selectedRoleGroupsCount === 0) {
      return undefined;
    }
    const label = `All users in ${pluralize(
      "department",
      selectedRoleGroupsCount,
    )}`;
    if (props.showUserTypeOptions) {
      return !oneUserTypeSelected
        ? label
        : `and users in ${pluralize("department", selectedRoleGroupsCount)}`;
    } else {
      return label;
    }
  }, [
    oneUserTypeSelected,
    props.showUserTypeOptions,
    selectedRoleGroupsCount,
    showRoleGroups,
  ]);
  const hasUserTypeRoleGroupsOrRolesFilters = useMemo(() => {
    return (
      props.input.allAdmins ||
      props.input.allManagers ||
      props.input.allTrainees ||
      !!props.input.roleIds?.length ||
      !!props.input.roleGroupIds?.length
    );
  }, [
    props.input.allAdmins,
    props.input.allManagers,
    props.input.allTrainees,
    props.input.roleIds?.length,
    props.input.roleGroupIds?.length,
  ]);
  const showLocationGroups = useMemo(() => {
    return (
      selectedLocationGroupsCount.all > 0 && hasUserTypeRoleGroupsOrRolesFilters
    );
  }, [selectedLocationGroupsCount.all, hasUserTypeRoleGroupsOrRolesFilters]);
  const locationGroupsLabel = useMemo(() => {
    if (!showLocationGroups || !hasUserTypeRoleGroupsOrRolesFilters) {
      return undefined;
    }

    const allUsersLabel = "in";
    const brandsLabel = `${allUsersLabel} ${pluralize(
      "brand",
      selectedLocationGroupsCount.BRAND,
    )}`;

    const regionsLabel = `${allUsersLabel} ${pluralize(
      "region",
      selectedLocationGroupsCount.REGION,
    )}`;
    const franchisesLabel = `${allUsersLabel} ${pluralize(
      "franchise",
      selectedLocationGroupsCount.FRANCHISE,
    )}`;

    return {
      [LocationGroupType.Brand]: brandsLabel,
      [LocationGroupType.Region]: regionsLabel,
      [LocationGroupType.Franchise]: franchisesLabel,
    };
  }, [
    showLocationGroups,
    hasUserTypeRoleGroupsOrRolesFilters,
    selectedLocationGroupsCount.BRAND,
    selectedLocationGroupsCount.REGION,
    selectedLocationGroupsCount.FRANCHISE,
  ]);
  const showLocations = useMemo(() => {
    return selectedLocationsCount > 0 && hasUserTypeRoleGroupsOrRolesFilters;
  }, [selectedLocationsCount, hasUserTypeRoleGroupsOrRolesFilters]);
  const locationsLabel = useMemo(() => {
    if (!showLocations || !hasUserTypeRoleGroupsOrRolesFilters) {
      return undefined;
    }
    let allUsersLabel = "";
    if (props.showUserTypeOptions) {
      if (!showRoles) {
        allUsersLabel = showLocationGroups ? "and at" : "All users at";
      } else {
        allUsersLabel = showLocationGroups ? "and at" : "at";
      }
    } else {
      if (showRoles && showLocationGroups) {
        allUsersLabel = "and at";
      } else if (showRoles && !showLocationGroups) {
        allUsersLabel = "at";
      } else if (showLocationGroups) {
        allUsersLabel = "and at";
      } else {
        allUsersLabel = "All users at";
      }
    }
    return `${allUsersLabel} ${pluralize("location", selectedLocationsCount)}`;
  }, [
    showLocations,
    hasUserTypeRoleGroupsOrRolesFilters,
    props.showUserTypeOptions,
    selectedLocationsCount,
    showRoles,
    showLocationGroups,
  ]);
  const showUsers = useMemo(() => {
    return selectedUsersCount > 0;
  }, [selectedUsersCount]);
  const usersLabel = useMemo(() => {
    const base = pluralize("user", selectedUsersCount);
    if (showRoles || showLocations || showLocationGroups) {
      return `and ${base}`;
    }
    return capitalizeFirstLetter(base);
  }, [selectedUsersCount, showLocationGroups, showLocations, showRoles]);

  return useMemo(() => {
    return {
      hasSelection,
      nestLocationAndLocationGroupSelections,
      showRoles,
      rolesLabel,
      userTypesLabel,
      showLocationGroups,
      locationGroupsLabel,
      showLocations,
      locationsLabel,
      showUsers,
      usersLabel,
      showRoleGroups,
      roleGroupsLabel,
    };
  }, [
    hasSelection,
    locationGroupsLabel,
    locationsLabel,
    nestLocationAndLocationGroupSelections,
    roleGroupsLabel,
    rolesLabel,
    showLocationGroups,
    showLocations,
    showRoleGroups,
    showRoles,
    showUsers,
    userTypesLabel,
    usersLabel,
  ]);
};

export default useUserSelectionPreviewState;
