import { useEffect, useState } from "react";

export type Dimension = {
  width: number;
  height: number;
};
export const useScreenDimensions = (): Dimension => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const resizeListener = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [setDimensions]);

  return dimensions;
};
