import gql from "graphql-tag";
import { useMemo } from "react";
import {
  LocationGroupThinFragment,
  useGetLocationGroupsQuery,
} from "./useLocationGroups.generated";
import { LocationGroupType } from "@src/types.generated";
import uniq from "lodash/uniq";

type UseLocationGroups = {
  locationGroups: LocationGroupThinFragment[];
  locationGroupTypes: LocationGroupType[];
  lgTypeToLgs: Record<LocationGroupType, LocationGroupThinFragment[]>;
  loading: boolean;
};

const useLocationGroups = (): UseLocationGroups => {
  const { data, previousData, loading } = useGetLocationGroupsQuery();
  const locationGroups = useMemo(() => {
    const d = data || previousData;
    return d?.LocationGroups.objects || [];
  }, [data, previousData]);
  const locationGroupTypes: LocationGroupType[] = useMemo(() => {
    const configTypes =
      data?.Me?.user.org?.locationGroupTypeConfigs.map((x) => x.type) || [];
    const typesFromLocationsList = locationGroups.map((x) => x.type);
    return uniq([...configTypes, ...typesFromLocationsList]);
  }, [data?.Me?.user.org?.locationGroupTypeConfigs, locationGroups]);
  const lgTypeToLgs: Record<LocationGroupType, LocationGroupThinFragment[]> =
    useMemo(() => {
      return Object.values(LocationGroupType).reduce(
        (acc, type) => {
          acc[type] = locationGroups.filter((x) => x.type === type);
          return acc;
        },
        {} as Record<LocationGroupType, LocationGroupThinFragment[]>,
      );
    }, [locationGroups]);
  return {
    locationGroups,
    loading,
    locationGroupTypes,
    lgTypeToLgs,
  };
};

gql`
  query GetLocationGroups {
    LocationGroups {
      totalCount
      objects {
        ...LocationGroupThin
      }
    }
    Me {
      user {
        id
        org {
          id
          locationGroupTypeConfigs {
            id
            type
          }
        }
      }
    }
  }
  fragment LocationGroupThin on LocationGroup {
    id
    name
    type
  }
`;

export default useLocationGroups;
