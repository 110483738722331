import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLocationGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLocationGroupsQuery = { __typename?: 'Query', LocationGroups: { __typename?: 'LocationGroups', totalCount: number, objects: Array<{ __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType }> }, Me?: { __typename?: 'Me', user: { __typename?: 'Employee', id: number, org?: { __typename?: 'Org', id: number, locationGroupTypeConfigs: Array<{ __typename?: 'OrgLocationGroupTypeConfig', id: string, type: Types.LocationGroupType }> } | null } } | null };

export type LocationGroupThinFragment = { __typename?: 'LocationGroup', id: string, name: string, type: Types.LocationGroupType };

export const LocationGroupThinFragmentDoc = gql`
    fragment LocationGroupThin on LocationGroup {
  id
  name
  type
}
    `;
export const GetLocationGroupsDocument = gql`
    query GetLocationGroups {
  LocationGroups {
    totalCount
    objects {
      ...LocationGroupThin
    }
  }
  Me {
    user {
      id
      org {
        id
        locationGroupTypeConfigs {
          id
          type
        }
      }
    }
  }
}
    ${LocationGroupThinFragmentDoc}`;

/**
 * __useGetLocationGroupsQuery__
 *
 * To run a query within a React component, call `useGetLocationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationGroupsQuery, GetLocationGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationGroupsQuery, GetLocationGroupsQueryVariables>(GetLocationGroupsDocument, options);
      }
export function useGetLocationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationGroupsQuery, GetLocationGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationGroupsQuery, GetLocationGroupsQueryVariables>(GetLocationGroupsDocument, options);
        }
export type GetLocationGroupsQueryHookResult = ReturnType<typeof useGetLocationGroupsQuery>;
export type GetLocationGroupsLazyQueryHookResult = ReturnType<typeof useGetLocationGroupsLazyQuery>;
export type GetLocationGroupsQueryResult = Apollo.QueryResult<GetLocationGroupsQuery, GetLocationGroupsQueryVariables>;