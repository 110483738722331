import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRoleGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRoleGroupsQuery = { __typename?: 'Query', RoleGroups: { __typename?: 'RoleGroups', totalCount: number, objects: Array<{ __typename?: 'RoleGroup', id: string, name: string }> } };

export type RoleGroupThinFragment = { __typename?: 'RoleGroup', id: string, name: string };

export const RoleGroupThinFragmentDoc = gql`
    fragment RoleGroupThin on RoleGroup {
  id
  name
}
    `;
export const GetRoleGroupsDocument = gql`
    query GetRoleGroups {
  RoleGroups {
    totalCount
    objects {
      ...RoleGroupThin
    }
  }
}
    ${RoleGroupThinFragmentDoc}`;

/**
 * __useGetRoleGroupsQuery__
 *
 * To run a query within a React component, call `useGetRoleGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GetRoleGroupsQuery, GetRoleGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRoleGroupsQuery, GetRoleGroupsQueryVariables>(GetRoleGroupsDocument, options);
      }
export function useGetRoleGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRoleGroupsQuery, GetRoleGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRoleGroupsQuery, GetRoleGroupsQueryVariables>(GetRoleGroupsDocument, options);
        }
export type GetRoleGroupsQueryHookResult = ReturnType<typeof useGetRoleGroupsQuery>;
export type GetRoleGroupsLazyQueryHookResult = ReturnType<typeof useGetRoleGroupsLazyQuery>;
export type GetRoleGroupsQueryResult = Apollo.QueryResult<GetRoleGroupsQuery, GetRoleGroupsQueryVariables>;