import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersForUserSelectionQueryVariables = Types.Exact<{
  input: Types.UserSelectionInput;
}>;


export type GetUsersForUserSelectionQuery = { __typename?: 'Query', GetUsersForUserSelection: Array<{ __typename?: 'Employee', id: number }> };


export const GetUsersForUserSelectionDocument = gql`
    query GetUsersForUserSelection($input: UserSelectionInput!) {
  GetUsersForUserSelection(input: $input) {
    id
  }
}
    `;

/**
 * __useGetUsersForUserSelectionQuery__
 *
 * To run a query within a React component, call `useGetUsersForUserSelectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForUserSelectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForUserSelectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUsersForUserSelectionQuery(baseOptions: Apollo.QueryHookOptions<GetUsersForUserSelectionQuery, GetUsersForUserSelectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersForUserSelectionQuery, GetUsersForUserSelectionQueryVariables>(GetUsersForUserSelectionDocument, options);
      }
export function useGetUsersForUserSelectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersForUserSelectionQuery, GetUsersForUserSelectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersForUserSelectionQuery, GetUsersForUserSelectionQueryVariables>(GetUsersForUserSelectionDocument, options);
        }
export type GetUsersForUserSelectionQueryHookResult = ReturnType<typeof useGetUsersForUserSelectionQuery>;
export type GetUsersForUserSelectionLazyQueryHookResult = ReturnType<typeof useGetUsersForUserSelectionLazyQuery>;
export type GetUsersForUserSelectionQueryResult = Apollo.QueryResult<GetUsersForUserSelectionQuery, GetUsersForUserSelectionQueryVariables>;