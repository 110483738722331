import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TranslateTextMutationVariables = Types.Exact<{
  text: Array<Types.Scalars['String']> | Types.Scalars['String'];
  targetLanguage: Types.Scalars['Language'];
}>;


export type TranslateTextMutation = { __typename?: 'Mutation', translateText: { __typename?: 'TranslatedTextMutationResult', language: Language, text: Array<string>, success: boolean } };

export type CatalogCoursePreviewQueryVariables = Types.Exact<{
  courseId: Types.Scalars['Int'];
}>;


export type CatalogCoursePreviewQuery = { __typename?: 'Query', AdminCourse?: { __typename?: 'Course', id: number, libraryItem: { __typename?: 'LibraryItem', id: string }, publishedVersion?: { __typename?: 'CourseVersion', id: number } | null } | null };


export const TranslateTextDocument = gql`
    mutation translateText($text: [String!]!, $targetLanguage: Language!) {
  translateText(text: $text, targetLanguage: $targetLanguage) {
    language
    text
    success
  }
}
    `;
export type TranslateTextMutationFn = Apollo.MutationFunction<TranslateTextMutation, TranslateTextMutationVariables>;

/**
 * __useTranslateTextMutation__
 *
 * To run a mutation, you first call `useTranslateTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranslateTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [translateTextMutation, { data, loading, error }] = useTranslateTextMutation({
 *   variables: {
 *      text: // value for 'text'
 *      targetLanguage: // value for 'targetLanguage'
 *   },
 * });
 */
export function useTranslateTextMutation(baseOptions?: Apollo.MutationHookOptions<TranslateTextMutation, TranslateTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TranslateTextMutation, TranslateTextMutationVariables>(TranslateTextDocument, options);
      }
export type TranslateTextMutationHookResult = ReturnType<typeof useTranslateTextMutation>;
export type TranslateTextMutationResult = Apollo.MutationResult<TranslateTextMutation>;
export type TranslateTextMutationOptions = Apollo.BaseMutationOptions<TranslateTextMutation, TranslateTextMutationVariables>;
export const CatalogCoursePreviewDocument = gql`
    query CatalogCoursePreview($courseId: Int!) {
  AdminCourse(id: $courseId) {
    id
    libraryItem {
      id
    }
    publishedVersion {
      id
    }
  }
}
    `;

/**
 * __useCatalogCoursePreviewQuery__
 *
 * To run a query within a React component, call `useCatalogCoursePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatalogCoursePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatalogCoursePreviewQuery({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useCatalogCoursePreviewQuery(baseOptions: Apollo.QueryHookOptions<CatalogCoursePreviewQuery, CatalogCoursePreviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatalogCoursePreviewQuery, CatalogCoursePreviewQueryVariables>(CatalogCoursePreviewDocument, options);
      }
export function useCatalogCoursePreviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatalogCoursePreviewQuery, CatalogCoursePreviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatalogCoursePreviewQuery, CatalogCoursePreviewQueryVariables>(CatalogCoursePreviewDocument, options);
        }
export type CatalogCoursePreviewQueryHookResult = ReturnType<typeof useCatalogCoursePreviewQuery>;
export type CatalogCoursePreviewLazyQueryHookResult = ReturnType<typeof useCatalogCoursePreviewLazyQuery>;
export type CatalogCoursePreviewQueryResult = Apollo.QueryResult<CatalogCoursePreviewQuery, CatalogCoursePreviewQueryVariables>;