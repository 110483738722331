import {
  DeprecatedColor,
  DeprecateTone,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Text from "@ui/text";
import { StyleDeclaration, StyleSheet, css } from "aphrodite";
import { CSSProperties, FC } from "react";

export type TooltipPosition = "Top" | "Right" | "Bottom" | "Left";

type Props = {
  text: string;
  textProps?: Partial<React.ComponentProps<typeof Text>>;
  caretPosition?: TooltipPosition;
  color?: DeprecatedColor | DeprecateTone;
  maxWidth?: number;
  multiline?: boolean;
  commandCharacters?: string[];
  containerStyle?: CSSProperties;
  containerStyleDeclaration?: StyleDeclaration;
  tooltipStyle?: CSSProperties;
  tooltipStyleDeclaration?: StyleDeclaration;
};

const Tooltip: FC<Props> = ({
  text,
  textProps,
  color = deprecatedTones.black,
  caretPosition,
  maxWidth = 0,
  multiline = false,
  commandCharacters,
  containerStyle,
  containerStyleDeclaration,
  tooltipStyle,
  tooltipStyleDeclaration,
}) => {
  return (
    <div
      className={css(
        styles.container,
        caretPosition === "Top" && styles.positionTop,
        caretPosition === "Right" && styles.positionRight,
        caretPosition === "Bottom" && styles.positionBottom,
        caretPosition === "Left" && styles.positionLeft,
        containerStyleDeclaration,
      )}
      style={containerStyle}
    >
      <div
        className={css(
          styles.cardContainer,
          maxWidth === 0 ? styles.displayFlex : styles.displayBlock,
          tooltipStyleDeclaration,
        )}
        style={{
          width: maxWidth === 0 ? "auto" : maxWidth,
          backgroundColor: color,
          ...tooltipStyle,
        }}
      >
        <Text
          type="P2"
          fontWeight="Medium"
          color={deprecatedTones.white}
          multiline={multiline}
          {...textProps}
        >
          {text}
        </Text>
        {commandCharacters && (
          <div
            className={css(
              styles.commandContainer,
              maxWidth === 0 && styles.marginLeft6,
            )}
          >
            {commandCharacters?.map((character, index) => {
              return (
                <div
                  key={`${character}-${index}`}
                  className={css(styles.commandContainer)}
                >
                  <div className={css(styles.commandCharacterContainer)}>
                    <Text
                      type="P2"
                      fontWeight="SemiBold"
                      color={deprecatedTones.white}
                    >
                      {character}
                    </Text>
                  </div>
                  {index !== commandCharacters?.length - 1 && (
                    <Text
                      type="P2"
                      fontWeight="SemiBold"
                      color={deprecatedTones.white}
                      styleDeclaration={styles.horizontalMargin2}
                    >
                      +
                    </Text>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div
        className={css(
          styles.caretContainer,
          caretPosition === "Top" && styles.caretTop,
          caretPosition === "Right" && styles.caretRight,
          caretPosition === "Bottom" && styles.caretBottom,
          caretPosition === "Left" && styles.caretLeft,
          tooltipStyleDeclaration,
        )}
        style={{
          borderTopColor: caretPosition === "Bottom" ? color : "transparent",
          borderRightColor: caretPosition === "Left" ? color : "transparent",
          borderBottomColor: caretPosition === "Top" ? color : "transparent",
          borderLeftColor: caretPosition === "Right" ? color : "transparent",
          ...tooltipStyle,
        }}
      ></div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
  },
  positionTop: {
    flexDirection: "column-reverse",
  },
  positionRight: {
    flexDirection: "row",
  },
  positionBottom: {
    flexDirection: "column",
  },
  positionLeft: {
    flexDirection: "row-reverse",
  },
  cardContainer: {
    padding: 12,
    borderRadius: 8,
  },
  caretContainer: {
    width: 0,
    height: 0,
  },
  caretTop: {
    borderRight: "6px solid transparent",
    borderBottomWidth: 6,
    borderBottomStyle: "solid",
    borderLeft: "6px solid transparent",
  },
  caretRight: {
    borderTop: "6px solid transparent",
    borderBottom: "6px solid transparent",
    borderLeftWidth: 6,
    borderLeftStyle: "solid",
  },
  caretBottom: {
    borderTopWidth: 6,
    borderTopStyle: "solid",
    borderRight: "6px solid transparent",
    borderLeft: "6px solid transparent",
  },
  caretLeft: {
    borderTop: "6px solid transparent",
    borderBottom: "6px solid transparent",
    borderRightWidth: 6,
    borderRightStyle: "solid",
  },
  inlineText: {
    display: "inline-block",
  },
  commandContainer: {
    display: "flex",
    alignItems: "center",
  },
  commandCharacterContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 20,
    height: 20,
    borderRadius: 2,
    backgroundColor: deprecatedTones.gray11,
  },
  horizontalMargin2: {
    marginRight: 4,
    marginLeft: 4,
  },
  marginLeft6: {
    marginLeft: 8,
  },
  displayBlock: {
    display: "block",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
});

export default Tooltip;
