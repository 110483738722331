import {
  curves,
  durations,
  properties,
} from "@src/deprecatedDesignSystem/styles/transitions";
import Link from "@src/deprecatedDesignSystem/components/Link";
import Text from "@ui/text";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { Route } from "nextjs-routes";
import { CSSProperties, FC, useMemo, useState } from "react";
import { deprecatedTones } from "../styles/deprecatedColors";
import DeprecatedIcon, { DeprecatedIconType } from "./DeprecatedIcon";

export type Link = { text: string; url: Route };

export type InlineEditButtonProps = {
  values: string[] | Link[];
  onClick?: () => void;
  loading?: boolean;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  placeholder?: string;
  iconType?: DeprecatedIconType;
  alwaysShowIcon?: boolean;
  sortValues?: boolean;
};

const InlineEditButton: FC<InlineEditButtonProps> = ({
  values,
  onClick,
  loading,
  style,
  styleDeclaration,
  placeholder = "Empty",
  iconType = "pencil",
  alwaysShowIcon = false,
  sortValues = true,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const areValuesLinks = useMemo(() => {
    return typeof values[0] === "string" ? false : true;
  }, [values]);
  const sortedValues = useMemo(() => {
    if (sortValues) {
      return values.sort();
    } else {
      return values;
    }
  }, [sortValues, values]);

  return (
    <div
      className={css(
        styles.container,
        onClick && !loading && styles.containerHover,
        styleDeclaration,
      )}
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={css(styles.valuesContainer)}
        onClick={() => {
          if (onClick && !loading && !areValuesLinks) onClick();
        }}
      >
        {loading && (
          <Text type="P2" fontWeight="SemiBold" multiline loading={true}>
            {""}
          </Text>
        )}
        {sortedValues.length === 0 && !loading && (
          <Text type="P2" multiline color={deprecatedTones.gray5}>
            {placeholder}
          </Text>
        )}
        {!loading &&
          sortedValues.map((value, index) => {
            if (typeof value === "string") {
              return (
                <span
                  key={`text-${index}-${value}`}
                  className={css(styles.valueContainer)}
                >
                  <Text type="P2" fontWeight="Regular" multiline>
                    {value}
                  </Text>
                  {index !== values.length - 1 && (
                    <span className={css(styles.spacer)}>,</span>
                  )}
                </span>
              );
            } else {
              return (
                <>
                  <span
                    key={`text-${index}-${value}`}
                    className={css(styles.valueContainer)}
                  >
                    <Link
                      text={value.text}
                      href={value.url}
                      type="P2"
                      fontWeight="Regular"
                      target="_blank"
                      multiline
                      underline
                    />
                  </span>
                  <span className={css(styles.spacer)}>
                    {index !== values.length - 1 && ","}
                  </span>
                </>
              );
            }
          })}
      </div>
      <div
        className={css(styles.spaceContainer)}
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        <DeprecatedIcon
          type={iconType}
          color={
            (isHovered || alwaysShowIcon) && onClick
              ? deprecatedTones.gray8
              : "transparent"
          }
        />
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: "transparent",
    minHeight: 32,
  },
  containerHover: {
    cursor: "pointer",
    transitionProperty: properties.backgroundColor,
    transitionDuration: durations.S025,
    transitionTimingFunction: curves.bezier,
    ":hover": {
      backgroundColor: deprecatedTones.gray5Alpha,
    },
  },
  overlayContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  valuesContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 8,
  },
  spaceContainer: {
    flex: 1,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    alignSelf: "stretch",
    paddingTop: 5,
    paddingRight: 6,
    paddingBottom: 5,
  },
  valueContainer: {
    display: "flex",
  },
  spacer: {
    width: 10,
  },
});

export default InlineEditButton;
