import {
  ActionPermissionsContext,
  ActionPermissionsContextState,
} from "@contexts/ActionPermissionsContext";
import { useContext } from "react";

const useActionPermissions = (): ActionPermissionsContextState => {
  return useContext(ActionPermissionsContext);
};

export default useActionPermissions;
