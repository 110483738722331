import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";

const styles = {
  focus: {
    boxShadow: `0 0 0 3px ${deprecatedColors.primaryContainer}`,
    border: `1px solid ${deprecatedTones.blue5}`,
  },
  focusError: {
    boxShadow: `0 0 0 3px ${deprecatedColors.errorContainer}`,
    border: `1px solid ${deprecatedTones.red11}`,
  },
  dropdownShadow: {
    boxShadow:
      "0px 8px 12px 6px rgba(142, 145, 146, 0.15), 0px 4px 4px rgba(142, 145, 146, 0.3)",
  },
};

export default styles;
