import { useEffect, useState, useRef, useCallback } from "react";

export type HeightAndWidth = {
  height: number | null;
  width: number | null;
};

type Return = [React.RefObject<HTMLDivElement>, HeightAndWidth];

const useRefHeightAndWidth = (): Return => {
  const [heightAndWidth, setHeightAndWidth] = useState<HeightAndWidth>({
    height: null,
    width: null,
  });

  const ref = useRef(null);

  const setHeightAndWidthCallback = useCallback(() => {
    setHeightAndWidth({
      // @ts-ignore
      height: ref.current?.clientHeight | null,
      // @ts-ignore
      width: ref.current?.clientWidth | null,
    });
  }, []);

  useEffect(() => {
    setHeightAndWidthCallback();
  }, [
    // @ts-ignore
    ref.current?.clientHeight,
    // @ts-ignore
    ref.current?.clientWidth,
    setHeightAndWidthCallback,
  ]);

  useEffect(() => {
    window.addEventListener("resize", setHeightAndWidthCallback);
    return () => {
      window.removeEventListener("resize", setHeightAndWidthCallback);
    };
  }, [setHeightAndWidthCallback]);
  return [ref, heightAndWidth];
};

export default useRefHeightAndWidth;
