import { gql } from "@apollo/client";
import { useGetUsersForUserSelectionQuery } from "@components/contentLibrary/assignmentModals/hooks/useGetUsersForOneOffAssignment.generated";
import { UserSelectionInput } from "@src/types.generated";
import { useMemo } from "react";

const useGetUsersForOneOffAssignment = (
  input: UserSelectionInput,
): number[] => {
  const { data: userSelectData, previousData: previousUserSelectionData } =
    useGetUsersForUserSelectionQuery({
      variables: {
        input,
      },
    });
  return useMemo(() => {
    const users =
      userSelectData?.GetUsersForUserSelection ||
      previousUserSelectionData?.GetUsersForUserSelection ||
      [];
    return users.map((u) => u.id);
  }, [userSelectData, previousUserSelectionData]);
};

export default useGetUsersForOneOffAssignment;

gql`
  query GetUsersForUserSelection($input: UserSelectionInput!) {
    GetUsersForUserSelection(input: $input) {
      id
    }
  }
`;
