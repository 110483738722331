import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateRoleMutationVariables = Types.Exact<{
  input: Types.CreateRoleInput;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'RoleMutationResult', success: boolean, role?: { __typename?: 'Role', id: number, name: string, roleGroupMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, roleGroup: { __typename?: 'RoleGroup', id: string, name: string } }>, roleActionPermissions?: { __typename?: 'RoleActionPermissions', id: string, roleUserType: Types.UserType, requireClockInConfirmation: boolean, actionPermissions: { __typename?: 'ActionPermissionSet', id: string, changeOrgSettings?: boolean | null, manageOrgIntegrations?: boolean | null, manageBilling?: boolean | null, inviteOthers?: boolean | null, createContent?: boolean | null, manageLibrary?: boolean | null, assignContent?: boolean | null, unassignContent?: boolean | null, updateAutomations?: boolean | null, verifySkills?: boolean | null, viewReporting?: boolean | null, sendMessages?: boolean | null, editTrainees?: boolean | null, deactivateTrainees?: boolean | null, managePremiumContent?: boolean | null, promoteTrainees?: boolean | null, markAsComplete?: boolean | null } } | null, directReports: Array<{ __typename?: 'Role', id: number, name: string }> } | null } };

export type RoleWithPermissionsFragment = { __typename?: 'Role', id: number, name: string, roleGroupMemberships: Array<{ __typename?: 'RoleGroupMembership', id: string, roleGroup: { __typename?: 'RoleGroup', id: string, name: string } }>, roleActionPermissions?: { __typename?: 'RoleActionPermissions', id: string, roleUserType: Types.UserType, requireClockInConfirmation: boolean, actionPermissions: { __typename?: 'ActionPermissionSet', id: string, changeOrgSettings?: boolean | null, manageOrgIntegrations?: boolean | null, manageBilling?: boolean | null, inviteOthers?: boolean | null, createContent?: boolean | null, manageLibrary?: boolean | null, assignContent?: boolean | null, unassignContent?: boolean | null, updateAutomations?: boolean | null, verifySkills?: boolean | null, viewReporting?: boolean | null, sendMessages?: boolean | null, editTrainees?: boolean | null, deactivateTrainees?: boolean | null, managePremiumContent?: boolean | null, promoteTrainees?: boolean | null, markAsComplete?: boolean | null } } | null, directReports: Array<{ __typename?: 'Role', id: number, name: string }> };

export type ActionPermissionSetFragment = { __typename?: 'ActionPermissionSet', id: string, changeOrgSettings?: boolean | null, manageOrgIntegrations?: boolean | null, manageBilling?: boolean | null, inviteOthers?: boolean | null, createContent?: boolean | null, manageLibrary?: boolean | null, assignContent?: boolean | null, unassignContent?: boolean | null, updateAutomations?: boolean | null, verifySkills?: boolean | null, viewReporting?: boolean | null, sendMessages?: boolean | null, editTrainees?: boolean | null, deactivateTrainees?: boolean | null, managePremiumContent?: boolean | null, promoteTrainees?: boolean | null, markAsComplete?: boolean | null };

export type RoleActionPermissionsFragment = { __typename?: 'RoleActionPermissions', id: string, roleUserType: Types.UserType, requireClockInConfirmation: boolean, actionPermissions: { __typename?: 'ActionPermissionSet', id: string, changeOrgSettings?: boolean | null, manageOrgIntegrations?: boolean | null, manageBilling?: boolean | null, inviteOthers?: boolean | null, createContent?: boolean | null, manageLibrary?: boolean | null, assignContent?: boolean | null, unassignContent?: boolean | null, updateAutomations?: boolean | null, verifySkills?: boolean | null, viewReporting?: boolean | null, sendMessages?: boolean | null, editTrainees?: boolean | null, deactivateTrainees?: boolean | null, managePremiumContent?: boolean | null, promoteTrainees?: boolean | null, markAsComplete?: boolean | null } };

export const ActionPermissionSetFragmentDoc = gql`
    fragment ActionPermissionSet on ActionPermissionSet {
  id
  changeOrgSettings
  manageOrgIntegrations
  manageBilling
  inviteOthers
  createContent
  manageLibrary
  assignContent
  unassignContent
  updateAutomations
  verifySkills
  viewReporting
  sendMessages
  editTrainees
  deactivateTrainees
  managePremiumContent
  promoteTrainees
  markAsComplete
}
    `;
export const RoleActionPermissionsFragmentDoc = gql`
    fragment RoleActionPermissions on RoleActionPermissions {
  id
  roleUserType
  actionPermissions {
    ...ActionPermissionSet
  }
  requireClockInConfirmation
}
    ${ActionPermissionSetFragmentDoc}`;
export const RoleWithPermissionsFragmentDoc = gql`
    fragment RoleWithPermissions on Role {
  id
  name
  roleGroupMemberships {
    id
    roleGroup {
      id
      name
    }
  }
  roleActionPermissions {
    ...RoleActionPermissions
  }
  directReports {
    id
    name
  }
}
    ${RoleActionPermissionsFragmentDoc}`;
export const CreateRoleDocument = gql`
    mutation createRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    success
    role {
      ...RoleWithPermissions
    }
  }
}
    ${RoleWithPermissionsFragmentDoc}`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;