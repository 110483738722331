import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import MiscStyles from "@src/deprecatedDesignSystem/styles/MiscStyles";
import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
  textInput: {
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
    boxSizing: "border-box",
    boxShadow: "0 2px 3px rgba(0, 0, 0, 0.03)",
    borderRadius: 8,
    color: deprecatedColors.onBackground,
    lineHeight: "18px",
    fontSize: 14,
    padding: 12,
    height: 40,
    ":focus": {
      ...MiscStyles.focus,
    },
    ":hover:not(:focus):not(.error)": {
      border: "1px solid #b9bac6",
    },
    ".error": {
      border: `1px solid ${deprecatedColors.error}`,
    },
  },
  textFull: {
    color: deprecatedColors.onBackground,
    fontWeight: 500,
    fontSize: 14,
  },
  textMedium: {
    color: deprecatedColors.outline,
    fontWeight: 400,
    fontSize: 14,
  },
  textTiny: {
    color: deprecatedColors.outline,
    fontWeight: 400,
    fontSize: 13,
  },
  textSmallThin: {
    color: deprecatedTones.gray6,
    fontWeight: 400,
    fontSize: 13,
  },
  textSmall: {
    color: deprecatedColors.outline,
    fontWeight: 400,
    fontSize: 13,
  },
  textMainThin: {
    color: deprecatedColors.onBackground,
    fontWeight: 400,
    fontSize: 14,
  },
  textMain: {
    color: deprecatedColors.onBackground,
    fontWeight: 500,
    fontSize: 14,
  },
  textMainBold: {
    color: deprecatedColors.onBackground,
    fontWeight: 600,
    fontSize: 14,
  },
  textSmallBold: {
    color: deprecatedColors.onBackground,
    fontWeight: 600,
    fontSize: 13,
  },
  paragraphSmall: {
    fontSize: 13,
    color: deprecatedColors.onBackground,
    fontWeight: 400,
    lineHeight: "20px",
  },
  paragraphMain: {
    fontSize: 16,
    color: deprecatedColors.onBackground,
    fontWeight: 400,
    lineHeight: "24px",
  },
  heading: {
    color: deprecatedColors.onBackground,
    fontWeight: 600,
    fontSize: 28,
  },
  heading3: {
    color: deprecatedColors.onBackground,
    fontWeight: 600,
    fontSize: 24,
    lineHeight: "32px",
  },
  heading4: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "32px",
    letterSpacing: "-0.03em",
    strong: {
      color: deprecatedColors.onBackground,
    },
  },
  heading5: {
    color: deprecatedColors.onBackground,
    fontWeight: 600,
    fontSize: 16,
  },
  displayMain: {
    fontWeight: 500,
    color: deprecatedColors.onBackground,
    fontSize: 14,
    lineHeight: "24px",
  },
  displayMainLight: {
    fontWeight: 500,
    color: deprecatedTones.gray5,
    fontSize: 14,
    lineHeight: "24px",
  },
  displayMainBold: {
    fontWeight: 600,
    color: deprecatedColors.onBackground,
    fontSize: 14,
    lineHeight: "24px",
  },
  displayMainThin: {
    fontWeight: 400,
    color: deprecatedColors.outline,
    fontSize: 14,
    lineHeight: "24px",
  },
  displaySmall: {
    fontWeight: 500,
    color: deprecatedColors.onPrimaryContainer,
    fontSize: 13,
  },
  displayTiny: {
    fontWeight: 400,
    color: deprecatedTones.gray11,
    fontSize: 12,
  },
  displaySmallThin: {
    fontWeight: 500,
    color: deprecatedTones.gray6,
    fontSize: 13,
  },
  link: {
    color: deprecatedColors.primary,
    textDecoration: "underline",
  },
  paragraph: {
    fontSize: 14,
    lineHeight: "20px",
    color: deprecatedTones.gray11,
  },
});
