import Modal from "@src/deprecatedDesignSystem/components/Modal";
import Spacer from "@src/components/ui/Spacer";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Button from "@src/deprecatedDesignSystem/components/Button";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import { useModal } from "@src/hooks/useModal";
import { pluralize } from "@src/utils/strings";
import { FC } from "react";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";

type Props = {
  selectedItemsCount: number;
  onConfirm: () => void;
};

const UpdateAccessMultiSelectConfirmationModal: FC<Props> = ({
  selectedItemsCount,
  onConfirm,
}) => {
  const { closeModal } = useModal();

  return (
    <Modal growHeight style={{ padding: 24, paddingBottom: 32 }}>
      <AutoLayout direction="vertical" alignSelf="stretch">
        <DeprecatedIcon
          type="warning-triangle"
          iconSize={32}
          containerSize={56}
          style={{ backgroundColor: deprecatedTones.yellow3, borderRadius: 8 }}
        />
        <Spacer size={32} />
        <Text type="P1" fontWeight="SemiBold" multiline>
          Are you sure you want to update access for{" "}
          {selectedItemsCount > 1 ? "these" : "this"} {selectedItemsCount}{" "}
          {pluralize("item", selectedItemsCount)}?
        </Text>
        <Spacer size={8} />
        <Text
          type="P2"
          fontWeight="Medium"
          color={deprecatedTones.gray6}
          multiline
        >
          User access will be updated immediately. Items must be published for
          access to be applied.
        </Text>
        <Spacer size={48} />
        <AutoLayout
          spaceBetweenItems={8}
          alignSelf="stretch"
          alignmentHorizontal="right"
        >
          <Button
            text="Close"
            variant="Outline"
            onClick={() => {
              closeModal();
            }}
          />
          <Button
            text="Confirm"
            variant="Primary"
            onClick={() => {
              onConfirm();
              closeModal();
              closeModal();
            }}
          />
        </AutoLayout>
      </AutoLayout>
    </Modal>
  );
};

export default UpdateAccessMultiSelectConfirmationModal;
