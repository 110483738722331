import { useMemo } from "react";
import { LocationGroupType } from "@src/types.generated";
import useLocationGroups from "@hooks/useLocationGroups";
type Return = (
  | LocationGroupType.Region
  | LocationGroupType.Brand
  | LocationGroupType.Franchise
)[];
const useHiddenLocationGroupTypes = (): Return => {
  const { locationGroupTypes } = useLocationGroups();
  return useMemo(() => {
    const allLocationGroupTypes = Object.values(LocationGroupType);
    const locationGroupTypesNotInConfig: LocationGroupType[] = [
      ...allLocationGroupTypes,
    ].filter((x) => !locationGroupTypes.includes(x));
    return locationGroupTypesNotInConfig.filter((x) => {
      return (
        x === LocationGroupType.Region ||
        x === LocationGroupType.Brand ||
        x === LocationGroupType.Franchise
      );
    }) as Return;
  }, [locationGroupTypes]);
};

export default useHiddenLocationGroupTypes;
