import { gradientStyles } from "@components/ui/CoverImage";
import { useOpenGraphDataQuery } from "@components/ui/MediaPreview.generated";
import { DeprecatedIconType } from "@src/deprecatedDesignSystem/deprecatedIcons";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { hashStringToNumber } from "@src/deprecatedDesignSystem/components/Pill";
import Text from "@ui/text";
import { useMuxPreviewUrl } from "@hooks/useMuxPreviewUrl";
import { BackgroundGradient, LibraryItemType } from "@src/types.generated";
import VideoPlayer from "@src/deprecatedDesignSystem/components/VideoPlayer";
import { getLinkType, LinkType } from "@utils/files";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { CSSProperties, FC, useMemo } from "react";
import { OgData } from "@src/components/libraryItemDetailPages/course/OpenGraphLinkPreview";
import {
  CoverImageFragment,
  ImageUrlsFragment,
} from "@src/fragments.generated";

export type ContentAvatarSize =
  | "24px"
  | "28px"
  | "32px"
  | "40px"
  | "56px"
  | "64px"
  | "88px"
  | number;

export type ContentType =
  | "Course"
  | "Module"
  | "Skill"
  | "Resource"
  | "PremiumPath";

export const LIBRARY_ITEM_TYPE_TO_CONTENT_TYPE: Record<
  LibraryItemType,
  ContentType
> = {
  [LibraryItemType.Course]: "Course",
  [LibraryItemType.Path]: "Module",
  [LibraryItemType.Skill]: "Skill",
  [LibraryItemType.TrainingResource]: "Resource",
  [LibraryItemType.PremiumContent]: "PremiumPath",
  [LibraryItemType.Sharp]: "PremiumPath",
};

export const CONTENT_TYPE_COPY: Record<ContentType, string> = {
  Course: "Course",
  Module: "Module",
  Skill: "Check-in",
  Resource: "Resource",
  PremiumPath: "Path",
};

// This is a complicated way of saying "ids are optional"
export type CoverImage_ContentAvatar = Omit<
  CoverImageFragment,
  "id" | "imageUrls"
> & {
  id?: string;
  imageUrls?:
    | (Omit<ImageUrlsFragment, "id"> & {
        id?: string;
      })
    | null;
};

type MediaUrlsTranslationSet = {
  en?: string;
  myTranslation?: string;
};

type Media = {
  thumbnailImageUrl?: string | null;
  mediaUrls?: MediaUrlsTranslationSet;
};

type Props = {
  defaultBackgroundHashKey: string | number;
  size?: ContentAvatarSize;
  contentType: ContentType;
  coverImage?: CoverImage_ContentAvatar | null;
  media?: Media;
  iconSize?: number;
  fullSizeImage?: boolean;
  styleDeclaration?: StyleDeclaration;
  style?: CSSProperties;
};

const ContentAvatar: FC<Props> = ({
  defaultBackgroundHashKey,
  size = "40px",
  contentType,
  coverImage,
  fullSizeImage = false,
  styleDeclaration,
  style,
  iconSize = 20,
  media,
}) => {
  const backgroundGradient: BackgroundGradient = useMemo(() => {
    if (coverImage && coverImage.background) {
      return coverImage.background;
    }
    const hashedNumberId = hashStringToNumber(String(defaultBackgroundHashKey));
    const gradients: BackgroundGradient[] = Object.values(BackgroundGradient);
    return gradients[hashedNumberId % gradients.length];
  }, [defaultBackgroundHashKey, coverImage]);
  const emojiIcon: string | null | undefined =
    coverImage && coverImage.emojiIcon;
  const url = useMemo(() => {
    if (media?.thumbnailImageUrl) {
      return media.thumbnailImageUrl;
    }
    if (fullSizeImage) {
      return coverImage?.imageUrls?.medium || coverImage?.imageUrls?.original;
    }
    return (
      coverImage?.imageUrls?.thumb ||
      coverImage?.imageUrls?.medium ||
      coverImage?.imageUrls?.original ||
      media?.mediaUrls?.myTranslation ||
      media?.mediaUrls?.en
    );
  }, [fullSizeImage, coverImage, media]);
  const linkType = useMemo(() => {
    if (url) {
      return getLinkType(url);
    }
    return null;
  }, [url]);
  const { data: ogData } = useOpenGraphDataQuery({
    skip: !url,
    variables: { url: url || "" },
  });
  const shouldAttemptToDisplayPreview = useMemo(() => {
    return (
      (!!linkType &&
        [
          LinkType.IMAGE,
          LinkType.GIF,
          LinkType.MUX_VIDEO,
          LinkType.VIDEO,
          LinkType.HOSTED_VIDEO,
        ].includes(linkType)) ||
      (!!ogData?.OpenGraphData && ogData.OpenGraphData["og:image"])
    );
  }, [linkType, ogData?.OpenGraphData]);
  return (
    <div
      className={css(styleDeclaration)}
      style={{
        position: "relative",
        width: size,
        height: size,
        minWidth: size,
        borderRadius: 4,
        backgroundColor: deprecatedTones.gray4Alpha,
        overflow: "hidden",
        userSelect: "none",
        ...style,
      }}
    >
      {shouldAttemptToDisplayPreview && (
        <MediaThumbnail
          url={url || ""}
          alt={url || ""}
          ogData={ogData?.OpenGraphData as OgData | undefined}
          size={size}
          styleDeclaration={[styles.mediaThumbnail, styleDeclaration]}
          style={style}
        />
      )}
      {!shouldAttemptToDisplayPreview && (
        <div
          className={css(
            styles.iconContainer,
            backgroundGradient && gradientStyles[backgroundGradient],
            styleDeclaration,
          )}
          style={style}
        >
          {emojiIcon ? (
            <AutoLayout
              flex={1}
              alignSelf="stretch"
              alignmentVertical="center"
              alignmentHorizontal="center"
            >
              <Text type="P1">{emojiIcon}</Text>
            </AutoLayout>
          ) : (
            <DeprecatedIcon
              type={CONTENT_TYPE_TO_ICON[contentType]}
              containerSize={iconSize}
              iconSize={iconSize}
              color={deprecatedTones.white}
            />
          )}
        </div>
      )}
    </div>
  );
};

const CONTENT_TYPE_TO_ICON: Record<ContentType, DeprecatedIconType> = {
  Course: "bookmark",
  Module: "lines",
  PremiumPath: "lines",
  Resource: "file",
  Skill: "asterisk",
};

export function getResourceIconType(url: string): DeprecatedIconType {
  const linkType = getLinkType(url);
  if (linkType == LinkType.AUDIO) {
    return "speaker-volume-high";
  }
  return "link";
}

type MediaThumbnailProps = {
  url: string;
  alt?: string;
  styleDeclaration?: StyleDeclaration;
  iconSize?: number;
  size?: string | number;
  style?: CSSProperties;
  ogData?: OgData;
};

export const MediaThumbnail: FC<MediaThumbnailProps> = ({
  url,
  styleDeclaration,
  alt,
  iconSize,
  size = "40px",
  style,
  ogData,
}) => {
  const linkType = getLinkType(url);
  const muxPreviewUrl = useMuxPreviewUrl(url);
  const baseStyles: CSSProperties = {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: deprecatedTones.gray4Alpha,
    borderRadius: 8,
    objectFit: "cover",
    width: size,
    height: size,
  };
  if ([LinkType.IMAGE, LinkType.GIF, LinkType.MUX_VIDEO].includes(linkType)) {
    let formattedUrl = url;
    if (linkType == LinkType.MUX_VIDEO) {
      if (muxPreviewUrl === undefined) {
        return (
          <div
            style={{
              ...baseStyles,
              ...style,
            }}
            className={css(styles.resourceThumbnail, styleDeclaration)}
          >
            <DeprecatedIcon
              type="video-camera"
              color={deprecatedTones.gray10}
            />
          </div>
        );
      }
      formattedUrl = muxPreviewUrl;
    }
    return (
      <img
        className={css(styles.resourceThumbnail, styleDeclaration)}
        style={{ ...baseStyles, ...style }}
        src={formattedUrl}
        alt={alt}
      />
    );
  } else if (linkType === LinkType.VIDEO) {
    return (
      <AutoLayout
        style={{ position: "relative", width: size, height: size, ...style }}
      >
        <VideoPlayer
          videoProps={{
            className: css(styles.resourceThumbnail, styleDeclaration),
            style: { ...baseStyles, ...style },
            loop: true,
            muted: true,
          }}
          src={url}
        />
        <VideoIndicatorOverlay />
      </AutoLayout>
    );
  } else if (linkType === LinkType.PDF) {
    return (
      <div
        style={{ ...baseStyles, ...style }}
        className={css(styles.resourceThumbnail, styleDeclaration)}
      >
        <Text type={"P3"} fontWeight={"SemiBold"} color={deprecatedTones.gray7}>
          PDF
        </Text>
      </div>
    );
  } else {
    return (
      <OGThumbnail
        size={size}
        url={url}
        ogData={ogData}
        iconSize={iconSize}
        styleDeclaration={styleDeclaration}
        style={style}
      />
    );
  }
};

type OGThumbnailProps = {
  url: string;
  styleDeclaration?: StyleDeclaration;
  iconSize?: number;
  size: string | number;
  style?: CSSProperties;
  ogData?: OgData;
};

const OGThumbnail: FC<OGThumbnailProps> = ({
  url,
  styleDeclaration,
  iconSize,
  size,
  style,
  ogData,
}) => {
  let thumbnailUrl = null;
  let hasVideoData = false;
  if (ogData) {
    if (ogData["og:image"]) {
      thumbnailUrl = ogData["og:image"];
    }
    hasVideoData = !!ogData["og:video:url"];
  }
  if (thumbnailUrl === null) {
    return (
      <AutoLayout
        width={size}
        height={size}
        className={css(styles.resourceThumbnail, styleDeclaration)}
      >
        <DeprecatedIcon
          type={getResourceIconType(url)}
          color={deprecatedTones.gray10}
          iconSize={iconSize}
        />
      </AutoLayout>
    );
  }
  return (
    <AutoLayout
      style={{ position: "relative", width: size, height: size, ...style }}
    >
      <img
        className={css(
          styles.resourceThumbnail,
          styles.ogThumbnail,
          styleDeclaration,
        )}
        style={{ overflow: "hidden", width: size, height: size, ...style }}
        src={thumbnailUrl}
        alt={url}
      />
      {hasVideoData && <VideoIndicatorOverlay />}
    </AutoLayout>
  );
};

const MediaIndicatorOverlay: FC<{
  iconType: DeprecatedIconType;
  iconProps?: Partial<React.ComponentProps<typeof DeprecatedIcon>>;
}> = (props) => {
  return (
    <AutoLayout
      style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}
      alignmentHorizontal={"center"}
      alignmentVertical={"center"}
    >
      <AutoLayout
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        alignmentHorizontal={"center"}
        alignmentVertical={"center"}
      >
        <AutoLayout
          height={18}
          width={22}
          style={{
            backgroundColor: deprecatedTones.black,
            opacity: 0.5,
            borderRadius: 6,
          }}
        />
      </AutoLayout>
      <DeprecatedIcon
        type={props.iconType}
        color={deprecatedTones.white}
        strokeWidth={3}
        iconSize={12}
        {...props.iconProps}
        style={{
          zIndex: 1,
          ...props.iconProps?.style,
        }}
      />
    </AutoLayout>
  );
};

export const VideoIndicatorOverlay: FC = () => {
  return (
    <MediaIndicatorOverlay
      iconType={"triangle"}
      iconProps={{
        style: {
          rotate: "90deg",
        },
      }}
    />
  );
};

export const LinkIndicatorOverlay: FC = () => {
  return (
    <MediaIndicatorOverlay
      iconType={"link"}
      iconProps={{
        iconSize: 16,
        containerSize: 16,
        strokeWidth: 2,
      }}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    display: "block",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "0 4px 0 0",
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 2,
  },
  mediaThumbnail: {
    borderRadius: 4,
    border: "none",
    outline: "none",
    padding: 0,
  },
  ogThumbnail: {
    objectFit: "cover",
  },
  resourceThumbnail: {
    "&::-webkit-media-controls": {
      display: "none",
    },
  },
});

export default ContentAvatar;
