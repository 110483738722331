import { CourseVersionQuery } from "../course/build/CourseBuildContent.generated";
import { Atom, atom } from "jotai";
import { selectAtom } from "jotai/utils";
import { selectedSkillIdAtom } from "../skill/atoms";
import { isEqual } from "lodash";
import { BuilderActionContextContent } from "@src/contexts/BuilderActionContext";
import { CourseScreen } from "./hooks/useCourseScreensAtom";

export const courseVersionAtom = atom<
  CourseVersionQuery["AdminCourseVersion"] | undefined
>(undefined);

export const selectedCourseIdAtom = selectAtom(
  courseVersionAtom,
  (courseVersion) => {
    return courseVersion?.course?.id;
  },
  isEqual,
);
export const courseVersionIdAtom = selectAtom(
  courseVersionAtom,
  (val) => val?.id,
  isEqual,
);

export const courseDraftVersionSelectedAtom = selectAtom(
  courseVersionAtom,
  (val) => {
    if (!val) return false;
    return val?.id === val?.course.draftVersion.id;
  },
  isEqual,
);
export const coursePublishVersionSelectedAtom = selectAtom(
  courseVersionAtom,
  (val) => {
    if (!val) return false;
    return val?.id === val?.course.publishedVersion?.id;
  },
  isEqual,
);

export const courseVersionLessonsAtom = selectAtom(
  courseVersionAtom,
  (val) => val?.lessons,
  isEqual,
);
export const selectedContentIdsAtom = atom((get) => ({
  courseId: get(selectedCourseIdAtom),
  skillId: get(selectedSkillIdAtom),
}));

export const selectedScreenIdsAtom = atom<string[]>([]);

export const builderActionContentAtom = atom<BuilderActionContextContent[]>(
  (get) => {
    const courseVersion = get(courseVersionAtom);
    if (!courseVersion?.course.id) return [];
    return [
      {
        contentIds: { courseId: courseVersion.course.id },
        initialLastAppliedBuilderActionId:
          courseVersion.course.lastBuilderActionId,
      },
    ];
  },
);

export const courseVersionCourseAtom = selectAtom(
  courseVersionAtom,
  (val) => val?.course,
  isEqual,
);

export const courseVersionCourseIdAtom = selectAtom(
  courseVersionCourseAtom,
  (val) => val?.id,
  isEqual,
);

export const courseVersionNameAtom = selectAtom(
  courseVersionAtom,
  (courseVersion) => courseVersion?.course.libraryItem.name,
  isEqual,
);
export const courseVersionPublishedVersionAtom = selectAtom(
  courseVersionCourseAtom,
  (course) => course?.publishedVersion,
  isEqual,
);
export const courseVersionNameEnAtom = selectAtom(
  courseVersionNameAtom,
  (name) => name?.en || "",
  isEqual,
);
export const courseVersionDescriptionAtom = selectAtom(
  courseVersionAtom,
  (cv) => cv?.course.libraryItem.description,
  isEqual,
);
export const courseVersionDescriptionEnAtom = selectAtom(
  courseVersionDescriptionAtom,
  (desc) => desc?.en || "",
  isEqual,
);
export const courseVersionCoverImageAtom = selectAtom(
  courseVersionAtom,
  (courseVersion) => courseVersion?.course.libraryItem.coverImage,
  isEqual,
);
export const courseVersionCreatedAtAtom = selectAtom(
  courseVersionAtom,
  (cv) => cv?.createdAt,
  isEqual,
);

export const isEditingSubtitlesAtom = atom(false);

export const emptyScreensAtom = atom<CourseScreen[]>(() => []);

export const undefinedAtom = atom(undefined);

export const screenAtomIdFact = (
  screenAtom: Atom<CourseScreen>,
): Atom<string> => selectAtom(screenAtom, (val) => val.id, isEqual);

export const screenIndexFact = (screenAtom: Atom<CourseScreen>): Atom<number> =>
  selectAtom(screenAtom, (val) => val.index, isEqual);

export const shimmerOnChangeAtom = atom(false);
