import {
  DeprecatedToneName,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text, { TextFontWeight, TextType } from "@ui/text";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { CSSProperties, FC, MouseEvent } from "react";
import { isUndefined, truncate } from "lodash";

type Props = {
  title: string;
  subtitle?: string;
  onRemove?: (e: MouseEvent) => void;
  onClick?: () => void;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
  id?: number | string;
  color?: DeprecatedToneName;
  dataTestId?: string;
  textType?: TextType;
  textFontWeight?: TextFontWeight;
  truncateAt?: number;
};

const PILL_COLORS: DeprecatedToneName[] = [
  "blue",
  "purple",
  "green",
  "red",
  "orange",
];

type ColorValues = {
  backgroundColor: string;
  titleColor: string;
  subtitleColor: string;
  iconColor: string;
};
const toneNameToValues: { [key in DeprecatedToneName]: ColorValues } = {
  blue: {
    backgroundColor: deprecatedTones.blue1,
    titleColor: deprecatedTones.blue10,
    subtitleColor: deprecatedTones.blue7,
    iconColor: deprecatedTones.blue10,
  },
  purple: {
    backgroundColor: deprecatedTones.purple1,
    titleColor: deprecatedTones.purple10,
    subtitleColor: deprecatedTones.purple7,
    iconColor: deprecatedTones.purple10,
  },
  green: {
    backgroundColor: deprecatedTones.green1,
    titleColor: deprecatedTones.green10,
    subtitleColor: deprecatedTones.green7,
    iconColor: deprecatedTones.green10,
  },
  red: {
    backgroundColor: deprecatedTones.red1,
    titleColor: deprecatedTones.red10,
    subtitleColor: deprecatedTones.red7,
    iconColor: deprecatedTones.red10,
  },
  orange: {
    backgroundColor: deprecatedTones.orange1,
    titleColor: deprecatedTones.orange10,
    subtitleColor: deprecatedTones.orange7,
    iconColor: deprecatedTones.orange10,
  },
  yellow: {
    backgroundColor: deprecatedTones.yellow1,
    titleColor: deprecatedTones.yellow10,
    subtitleColor: deprecatedTones.yellow7,
    iconColor: deprecatedTones.yellow10,
  },
  gray: {
    backgroundColor: deprecatedTones.gray1,
    titleColor: deprecatedTones.gray10,
    subtitleColor: deprecatedTones.gray7,
    iconColor: deprecatedTones.gray10,
  },
};

const Pill: FC<Props> = ({
  title,
  subtitle,
  onClick,
  onRemove,
  color = "gray",
  id,
  style,
  styleDeclaration,
  dataTestId,
  textType = "P3",
  textFontWeight = "Regular",
  truncateAt,
}) => {
  const baseColor = isUndefined(id) ? color : getBaseColorFromId(id);
  const pillColors = toneNameToValues[baseColor];
  return (
    <div
      className={css(styles.container, styleDeclaration)}
      style={{
        backgroundColor: pillColors.backgroundColor,
        cursor: onClick ? "pointer" : "auto",
        ...style,
      }}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <Text
        type={textType}
        fontWeight={textFontWeight}
        color={pillColors.titleColor}
        style={{ whiteSpace: "nowrap" }}
      >
        {truncateAt ? truncate(title, { length: truncateAt }) : title}
      </Text>
      {subtitle && (
        <div className={css(styles.subtitleContainer)}>
          <Text type="P3" color={pillColors.subtitleColor}>
            {subtitle}
          </Text>
        </div>
      )}
      {onRemove && (
        <>
          <DeprecatedIcon
            type="close"
            iconSize={14}
            color={pillColors.iconColor}
            styleDeclaration={styles.remove}
            onClick={onRemove}
            containerSize={18}
          />
        </>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    width: "fit-content",
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: 4,
  },
  subtitleContainer: {
    marginLeft: 8,
  },
  remove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    height: "100%",
    marginRight: -4,
  },
});

export default Pill;

export const hashStringToNumber = (str: string): number => {
  if (!str) {
    return 1;
  }
  return Math.abs(
    str
      .split("")
      .map((v) => v.charCodeAt(0))
      .reduce((a, v) => (a + ((a << 7) + (a << 3))) ^ v),
  );
};

const idHash = (id: number | string): number => {
  if (typeof id === "number") {
    return id;
  } else {
    return hashStringToNumber(id);
  }
};

const getBaseColorFromId = (id: number | string): DeprecatedToneName => {
  return PILL_COLORS[idHash(id) % PILL_COLORS.length];
};

export const getPillColorsFromId = (id: number | string): ColorValues => {
  const baseColor = getBaseColorFromId(id);
  return toneNameToValues[baseColor];
};
