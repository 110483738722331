import { atom } from "jotai";
import { SkillBuilder_SkillVersionQuery } from "./SkillDetailPage.generated";

export const skillVersionAtom = atom<
  SkillBuilder_SkillVersionQuery["AdminSkillVersion"] | undefined
>(undefined);

export const selectedSkillIdAtom = atom<number | undefined>((get) => {
  return get(skillVersionAtom)?.skill.id;
});
