import { CSSProperties, FC } from "react";
import { css, StyleSheet, StyleDeclaration } from "aphrodite";
import {
  deprecatedTones,
  DeprecatedColor,
  DeprecateTone,
  DeprecatedShadedTone,
} from "../styles/deprecatedColors";

type Props = {
  color?: DeprecateTone | DeprecatedColor | DeprecatedShadedTone;
  style?: CSSProperties;
  styleDeclaration?: StyleDeclaration;
};

const HorizontalDivider: FC<Props> = ({
  color = deprecatedTones.gray5Alpha,
  style,
  styleDeclaration,
}) => (
  <div
    className={css(styles.container, styleDeclaration)}
    style={{ backgroundColor: color, ...style }}
  />
);

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 1,
  },
});

export default HorizontalDivider;
