import gql from "graphql-tag";
import { useMemo } from "react";
import { RoleThinFragment, useGetRolesQuery } from "./useRoles.generated";
import { UserType } from "@src/types.generated";
import { useFeatureFlag } from "./useFeatureFlag";

type UseRoles = {
  roles: RoleThinFragment[];
  loading: boolean;
};

const useRoles = (): UseRoles => {
  const { data, previousData, loading } = useGetRolesQuery();
  const roleHierarchyEnabled = useFeatureFlag("role-hierarchy");
  const roles = useMemo(() => {
    const d = data || previousData;
    const allRoles = d?.Roles.objects || [];
    const user = d?.Me?.user;
    const userHighestRoleLevel =
      user?.userType === UserType.Admin || !roleHierarchyEnabled
        ? 1
        : Math.min(
            ...(user?.roles.map((role) => role?.roleLevel || Infinity) || [
              Infinity,
            ]),
          );
    return allRoles.filter(
      (r) =>
        r.roleLevel &&
        (userHighestRoleLevel === 1 || r.roleLevel > userHighestRoleLevel),
    );
  }, [data, previousData, roleHierarchyEnabled]);
  return {
    roles,
    loading,
  };
};

gql`
  query GetRoles {
    Me {
      user {
        id
        userType
        roles {
          id
          roleLevel
        }
      }
    }
    Roles {
      objects {
        ...RoleThin
      }
    }
  }
  fragment RoleThin on Role {
    id
    name
    roleLevel
    userCount
    roleGroupMemberships {
      id
      roleGroup {
        id
        name
      }
    }
    roleActionPermissions {
      ...RoleActionPermissions
    }
    directReports {
      id
      name
    }
  }
`;

export default useRoles;
