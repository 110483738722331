import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import Text from "@ui/text";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import { motion } from "framer-motion";
import { CSSProperties, FC } from "react";

type Props = {
  checked: boolean;
  label: string;
  labelOnly?: boolean;
  onClick: () => void;
  disabled?: boolean;
  styleDeclaration?: StyleDeclaration;
  style?: CSSProperties;
};

const RadioButton: FC<Props> = ({
  checked,
  label,
  labelOnly = false,
  onClick,
  disabled,
  styleDeclaration,
  style,
}) => {
  return (
    <div
      className={css(styles.container, styleDeclaration)}
      onClick={onClick}
      style={{ ...style }}
    >
      {labelOnly ? null : (
        <button
          className={css(
            styles.radioContainer,
            disabled && styles.radioContainerDisabled,
            !disabled &&
              (checked
                ? styles.radioContainerCheckedNotDisabled
                : styles.radioContainerNotCheckedNotDisabled),
          )}
        >
          <motion.div
            variants={sizeVariants}
            initial="hidden"
            animate={checked ? "show" : "hidden"}
            className={css(
              styles.radioCheckCircle,
              disabled &&
                (checked
                  ? styles.radioCheckCircleCheckedDisabled
                  : styles.radioCheckCircleNotCheckedDisabled),
              !disabled && styles.radioCheckCircleNotDisabled,
            )}
          />
        </button>
      )}
      <Text type="P2" fontWeight="Regular" color={deprecatedTones.black}>
        {label}
      </Text>
    </div>
  );
};

const cubic = {
  ease: [0.45, 0.25, 0.25, 1],
  duration: 0.15,
};

const sizeVariants = {
  hidden: { width: 0, height: 0 },
  show: {
    width: 6,
    height: 6,
    transition: cubic,
  },
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  radioContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 16,
    height: 16,
    marginRight: 8,
    borderRadius: 16,
    padding: 0,
    transitionProperty: "outline",
    ":focus-visible": {
      outline: `2px solid ${deprecatedTones.blue3}`,
    },
  },
  radioCheckCircle: {
    backgroundColor: deprecatedTones.white,
    borderRadius: 6,
  },
  radioCheckCircleCheckedDisabled: {
    backgroundColor: deprecatedTones.gray5,
  },
  radioCheckCircleNotCheckedDisabled: {
    backgroundColor: deprecatedTones.gray4Alpha,
  },
  radioCheckCircleNotDisabled: {
    backgroundColor: deprecatedTones.white,
  },
  radioContainerCheckedNotDisabled: {
    border: `1px solid ${deprecatedTones.blue9}`,
    backgroundColor: deprecatedTones.blue9,
  },
  radioContainerNotCheckedNotDisabled: {
    border: `1px solid ${deprecatedTones.gray5}`,
    backgroundColor: deprecatedTones.white,
  },
  radioContainerDisabled: {
    border: `1px solid ${deprecatedTones.gray5}`,
    backgroundColor: deprecatedTones.gray4Alpha,
  },
});

export default RadioButton;
