export function launchConfetti(): void {
  setTimeout(() => {
    // we include a script in _document.tsx to load the js for this
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    confetti({
      ticks: 100,
      particleCount: 100,
      spread: 70,
      origin: { y: 0.8 },
      zIndex: 10000,
    });
  }, 0);
}
