import React, { FC, useMemo, useState } from "react";
import * as RadixPopover from "@radix-ui/react-popover";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { AnimatePresence, motion } from "framer-motion";
import { uuid4 } from "@utils/strings";

const SimpleDropdown: FC<{
  trigger: React.ReactNode;
  content: React.ReactNode;
  usePortal?: boolean; // breaks exit animation if true
  disableExitAnimation?: boolean;
  radixContentProps?: Partial<RadixPopover.PopoverContentProps>;
  radixRootProps?: Partial<React.ComponentProps<typeof RadixPopover.Root>>;
  radixTriggerProps?: Partial<RadixPopover.PopoverTriggerProps>;
}> = (props) => {
  const { style: contentStyleProp, ...restOfContentProps } =
    props.radixContentProps || {};
  const key = useMemo(() => uuid4(), []);
  const [baseInitial, baseAnimate] = [
    { opacity: 0, scale: 0.6, y: -42 },
    { opacity: 1, scale: 1, y: 0 },
  ];
  const align: RadixPopover.PopoverContentProps["align"] =
    props.radixContentProps?.align || "end";
  const [initial, animate] = useMemo(() => {
    if (align === "end") {
      return [
        { ...baseInitial, x: 24 },
        { ...baseAnimate, x: 0 },
      ];
    } else if (align === "start") {
      return [
        { ...baseInitial, x: -24 },
        { ...baseAnimate, x: 0 },
      ];
    } else if (align === "center") {
      return [{ ...baseInitial }, { ...baseAnimate }];
    } else {
      return [{}, {}];
    }
  }, [align, baseAnimate, baseInitial]);
  const [open, setOpen] = useState(false);
  const popoverContent = useMemo(() => {
    return (
      <RadixPopover.Content
        align={"end"}
        asChild
        sideOffset={8}
        {...restOfContentProps}
        style={{
          outline: "none",
          zIndex: 9999,
        }}
      >
        <motion.div
          key={key}
          initial={initial}
          animate={animate}
          transition={{ type: "spring", duration: 0.2 }}
          exit={!props.disableExitAnimation ? initial : undefined}
          style={{
            borderRadius: 8,
            backgroundColor: deprecatedTones.white,
            zIndex: 10,
            overflow: "hidden",
            boxShadow: "00px 0px 50px rgba(154, 146, 162, 0.21)",
            ...contentStyleProp,
          }}
        >
          {props.content}
        </motion.div>
      </RadixPopover.Content>
    );
  }, [
    animate,
    contentStyleProp,
    initial,
    key,
    props.content,
    props.disableExitAnimation,
    restOfContentProps,
  ]);
  return (
    <RadixPopover.Root
      {...props.radixRootProps}
      onOpenChange={(open) => {
        setOpen(open);
      }}
    >
      <RadixPopover.Trigger
        asChild
        onClick={(e) => e.stopPropagation()}
        {...props.radixTriggerProps}
      >
        {props.trigger}
      </RadixPopover.Trigger>
      {!props.usePortal && (
        <AnimatePresence>{open && popoverContent}</AnimatePresence>
      )}
      {props.usePortal && (
        <AnimatePresence>
          {open && <RadixPopover.Portal>{popoverContent}</RadixPopover.Portal>}
        </AnimatePresence>
      )}
    </RadixPopover.Root>
  );
};

export default SimpleDropdown;
