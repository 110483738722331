import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { EmployeeListItemFragmentDoc } from '../../../../fragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSelectedUsersQueryVariables = Types.Exact<{
  input: Types.GetPeopleInput;
}>;


export type GetSelectedUsersQuery = { __typename?: 'Query', People: { __typename?: 'Employees', totalCount: number, objects: Array<{ __typename?: 'Employee', id: number, createdAt: string, name: string, firstName: string, lastName: string, email?: string | null, phoneNumber?: string | null, language: Language, hasUsedApp: boolean, deactivatedAt?: string | null, deactivatedReason?: Types.DeactivationReason | null, userType: Types.UserType, lastActivityAt?: string | null, locations: Array<{ __typename?: 'Location', id: number, name: string }>, roles: Array<{ __typename?: 'Role', id: number, name: string }>, deactivatedBy?: { __typename?: 'Employee', id: number, name: string } | null, hrisEmployee?: { __typename?: 'HrisEmployee', id: number, remoteHrisId: string, firstName: string, lastName: string, startDate?: string | null } | null }> } };


export const GetSelectedUsersDocument = gql`
    query GetSelectedUsers($input: GetPeopleInput!) {
  People(input: $input) {
    totalCount
    objects {
      ...EmployeeListItem
    }
  }
}
    ${EmployeeListItemFragmentDoc}`;

/**
 * __useGetSelectedUsersQuery__
 *
 * To run a query within a React component, call `useGetSelectedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectedUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSelectedUsersQuery(baseOptions: Apollo.QueryHookOptions<GetSelectedUsersQuery, GetSelectedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelectedUsersQuery, GetSelectedUsersQueryVariables>(GetSelectedUsersDocument, options);
      }
export function useGetSelectedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelectedUsersQuery, GetSelectedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelectedUsersQuery, GetSelectedUsersQueryVariables>(GetSelectedUsersDocument, options);
        }
export type GetSelectedUsersQueryHookResult = ReturnType<typeof useGetSelectedUsersQuery>;
export type GetSelectedUsersLazyQueryHookResult = ReturnType<typeof useGetSelectedUsersLazyQuery>;
export type GetSelectedUsersQueryResult = Apollo.QueryResult<GetSelectedUsersQuery, GetSelectedUsersQueryVariables>;