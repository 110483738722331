import { useModal } from "@src/hooks/useModal";
import React, { FC } from "react";
import Modal from "./Modal";
import { css, StyleSheet } from "aphrodite";

type Props = {
  title: string;
  text: string | React.ReactNode;
  onConfirm: () => void;
  cancelEnabled?: boolean;
  confirmCopy?: string;
  confirmDisabled?: boolean;
  confirmLoading?: boolean;
  alert?: boolean;
};

const ConfirmationModal: FC<Props> = ({
  title,
  text,
  onConfirm,
  cancelEnabled = true,
  confirmCopy = "Confirm",
  confirmDisabled,
  confirmLoading,
  alert,
}) => {
  const { closeModal } = useModal();
  return (
    <Modal
      title={title}
      footerClassName={css(styles.modalFooter)}
      confirmButtonProps={{
        copy: confirmCopy,
        loading: confirmLoading,
        disabled: confirmDisabled,
        alert,
        onClick: () => {
          onConfirm();
          closeModal();
        },
      }}
      cancelButtonProps={
        cancelEnabled
          ? {
              copy: "Cancel",
              onClick: () => {
                closeModal();
              },
            }
          : undefined
      }
    >
      <div className={css(styles.body)}>
        <div className={css(styles.text)}>{text}</div>
      </div>
    </Modal>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 24,
    fontSize: 14,
    maxWidth: 400,
  },
  modalFooter: {
    borderTop: "none",
  },
  text: {
    marginTop: 12,
  },
});

export default ConfirmationModal;
