import useLocationGroups from "@src/hooks/useLocationGroups";
import { LocationGroupThinFragment } from "@src/hooks/useLocationGroups.generated";
import useLocations from "@src/hooks/useLocations";
import { LocationThinFragment } from "@src/hooks/useLocations.generated";
import useRoles from "@src/hooks/useRoles";
import { RoleThinFragment } from "@src/hooks/useRoles.generated";
import { useMemo } from "react";
import { gql } from "@apollo/client";
import { EmployeeThinFragment } from "@src/fragments.generated";
import { useGetSelectedUsersQuery } from "./useUserSelections.generated";
import { RoleGroupThinFragment } from "@src/hooks/useRoleGroups.generated";
import useRoleGroups from "@src/hooks/useRoleGroups";
import { LocationGroupType, UserSelectionInput } from "@src/types.generated";

type TotalSelectedLocationGroups = Record<LocationGroupType, number> & {
  all: number;
};
type UserSelections = {
  selectedLocationGroups: Record<
    LocationGroupType,
    LocationGroupThinFragment[]
  >;
  selectedLocations: LocationThinFragment[];
  selectedRoles: RoleThinFragment[];
  selectedRoleGroups: RoleGroupThinFragment[];
  selectedUsers: EmployeeThinFragment[];
  selectedLocationGroupsCount: TotalSelectedLocationGroups;
  selectedRoleGroupsCount: number;
  selectedLocationsCount: number;
  selectedRolesCount: number;
  selectedUsersCount: number;
};

const useUserSelections = (props: UserSelectionInput): UserSelections => {
  const { locationGroups } = useLocationGroups();
  const selectedFranchises = useMemo(() => {
    return locationGroups
      .filter((x) => props.locationGroupIds?.includes(x.id))
      .filter((x) => x.type === LocationGroupType.Franchise);
  }, [locationGroups, props.locationGroupIds]);
  const selectedBrands = useMemo(() => {
    return locationGroups
      .filter((x) => props.locationGroupIds?.includes(x.id))
      .filter((x) => x.type === LocationGroupType.Brand);
  }, [locationGroups, props.locationGroupIds]);
  const selectedRegions = useMemo(() => {
    return locationGroups
      .filter((x) => props.locationGroupIds?.includes(x.id))
      .filter((x) => x.type === LocationGroupType.Region);
  }, [locationGroups, props.locationGroupIds]);
  const selectedLocationGroups = useMemo(() => {
    return {
      [LocationGroupType.Brand]: selectedBrands,
      [LocationGroupType.Franchise]: selectedFranchises,
      [LocationGroupType.Region]: selectedRegions,
    };
  }, [selectedBrands, selectedFranchises, selectedRegions]);

  const { locations } = useLocations();
  const selectedLocations = useMemo(() => {
    return locations.filter((x) => props.locationIds?.includes(x.id));
  }, [locations, props.locationIds]);

  const { roleGroups } = useRoleGroups();
  const selectedRoleGroups = useMemo(() => {
    return roleGroups.filter((x) => props.roleGroupIds?.includes(x.id));
  }, [props.roleGroupIds, roleGroups]);

  const { roles } = useRoles();
  const selectedRoles = useMemo(() => {
    return roles.filter((x) => props.roleIds?.includes(x.id));
  }, [props.roleIds, roles]);

  const { data: users } = useGetSelectedUsersQuery({
    skip: !props.userIds?.length,
    variables: {
      input: { userIds: props.userIds ? { value: props.userIds } : undefined },
    },
  });
  const selectedUsers = useMemo(() => {
    return users?.People.objects || [];
  }, [users?.People.objects]);
  const selectedLocationGroupsCount = useMemo(() => {
    const totalBrands = selectedLocationGroups[LocationGroupType.Brand].length;
    const totalFranchises =
      selectedLocationGroups[LocationGroupType.Franchise].length;
    const totalRegions =
      selectedLocationGroups[LocationGroupType.Region].length;
    return {
      all: totalBrands + totalFranchises + totalRegions,
      [LocationGroupType.Brand]: totalBrands,
      [LocationGroupType.Franchise]: totalFranchises,
      [LocationGroupType.Region]: totalRegions,
    };
  }, [selectedLocationGroups]);

  return useMemo(() => {
    return {
      selectedLocations,
      selectedLocationGroups,
      selectedRoles,
      selectedRoleGroups,
      selectedUsers,
      selectedLocationGroupsCount,
      selectedRolesCount: selectedRoles.length,
      selectedUsersCount: selectedUsers.length,
      selectedLocationsCount: selectedLocations.length,
      selectedRoleGroupsCount: selectedRoleGroups.length,
    };
  }, [
    selectedLocationGroups,
    selectedLocationGroupsCount,
    selectedLocations,
    selectedRoleGroups,
    selectedRoles,
    selectedUsers,
  ]);
};

gql`
  query GetSelectedUsers($input: GetPeopleInput!) {
    People(input: $input) {
      totalCount
      objects {
        ...EmployeeListItem
      }
    }
  }
`;

export default useUserSelections;
