import { gql } from "@apollo/client";
import { PlanFragment, usePlanQuery } from "./usePlan.generated";

export const usePlan = (): PlanFragment | undefined => {
  const { data } = usePlanQuery({
    fetchPolicy: "cache-first",
  });
  return data?.BillingAccounts[0]?.subscriptions[0]?.plan;
};

export default usePlan;

gql`
  fragment Plan on Plan {
    id
    planType
    licenseType
  }
  query Plan {
    BillingAccounts(input: {}) {
      id
      subscriptions {
        id
        plan {
          ...Plan
        }
      }
    }
  }
`;
