import { FC, ReactNode } from "react";
import { css, StyleSheet } from "aphrodite";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { deprecatedTones } from "../styles/deprecatedColors";

type Props = {
  children: ReactNode | ReactNode[];
};

const InlineWrapCard: FC<Props> = ({ children }) => {
  return (
    <AutoLayout
      alignSelf="stretch"
      wrap="wrap"
      className={css(styles.inlineWrapCard)}
      paddingHorizontal={12}
      paddingVertical={8}
      alignmentVertical="center"
      spaceBetweenItems={4}
    >
      {children}
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  inlineWrapCard: {
    borderRadius: 12,
    backgroundColor: deprecatedTones.white,
    border: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
});

export default InlineWrapCard;
