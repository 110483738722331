import * as Types from '../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlanFragment = { __typename?: 'Plan', id: string, planType: Types.PlanType, licenseType: Types.LicenseType };

export type PlanQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PlanQuery = { __typename?: 'Query', BillingAccounts: Array<{ __typename?: 'BillingAccount', id: string, subscriptions: Array<{ __typename?: 'BillingSubscription', id: string, plan: { __typename?: 'Plan', id: string, planType: Types.PlanType, licenseType: Types.LicenseType } }> }> };

export const PlanFragmentDoc = gql`
    fragment Plan on Plan {
  id
  planType
  licenseType
}
    `;
export const PlanDocument = gql`
    query Plan {
  BillingAccounts(input: {}) {
    id
    subscriptions {
      id
      plan {
        ...Plan
      }
    }
  }
}
    ${PlanFragmentDoc}`;

/**
 * __usePlanQuery__
 *
 * To run a query within a React component, call `usePlanQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlanQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlanQuery(baseOptions?: Apollo.QueryHookOptions<PlanQuery, PlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
      }
export function usePlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlanQuery, PlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlanQuery, PlanQueryVariables>(PlanDocument, options);
        }
export type PlanQueryHookResult = ReturnType<typeof usePlanQuery>;
export type PlanLazyQueryHookResult = ReturnType<typeof usePlanLazyQuery>;
export type PlanQueryResult = Apollo.QueryResult<PlanQuery, PlanQueryVariables>;