import { useTranslateTextMutation } from "@components/home/operations.generated";
import { useTranslationCacheContext } from "@contexts/TranslationCacheContext";
import { useDebounce } from "@hooks/useDebounce";
import { usePreviousNonNullOrUndefined } from "@hooks/usePrevious";
import {
  TranslationOverrideObjectInput,
  TranslationSetInput,
  TranslationSetObjectInput,
} from "@src/types.generated";
import { uuid4 } from "@utils/strings";
import { isNotNullOrUndefined, isNullOrUndefined } from "@utils/typeguards";
import { cloneDeep } from "lodash";
import { useEffect, useMemo } from "react";

export const updateTranslationSet = <T extends TranslationSetInput>(args: {
  ts: T;
  lang: Language;
  text: string;
}): TranslationSetObjectInput => {
  const { ts, lang, text } = args;
  const tsWithId = {
    uuid: uuid4(),
    ...ts,
  };
  const langToOverrideMap = new Map<Language, TranslationOverrideObjectInput>(
    ts.translationOverrides?.map((x) => [x.language, { id: uuid4(), ...x }]),
  );
  if (lang === "en") {
    return {
      ...cloneDeep(tsWithId),
      en: text,
      translationOverrides: Array.from(langToOverrideMap.values()),
    };
  }

  langToOverrideMap.set(lang, {
    id: uuid4(),
    text: text,
    sourceText: ts.en,
    language: lang,
  });
  return {
    ...tsWithId,
    translationOverrides: Array.from(langToOverrideMap.values()),
  };
};

export const removeLangFromTranslationSetOverrides = <
  T extends TranslationSetInput,
>(
  ts: T,
  lang: Language,
): T => {
  return {
    ...ts,
    translationOverrides: ts.translationOverrides?.filter(
      (x) => x.language !== lang,
    ),
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function findTsEnValues(object: object): string[] {
  const result: string[] = [];
  findTsEnValuesRecursively(object, result);
  return result;
}

// eslint-disable-next-line @typescript-eslint/ban-types
function findTsEnValuesRecursively(item: object, result: string[]) {
  // @ts-ignore
  if (item.__typename === "TranslationSet" && item.en) {
    // @ts-ignore
    result.push(item.en);
    return;
  }
  Object.keys(item).forEach((key) => {
    // @ts-ignore
    if (typeof item[key] == "object" && item[key] !== null) {
      // @ts-ignore
      findTsEnValuesRecursively(item[key], result);
    }
  });
}

export const usePrePopulateTranslationCache = (args: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  obj: object | undefined | null;
  objId: string | number | undefined;
  lang: Language;
  isInTranslationMode: boolean;
}): void => {
  const { obj, objId, lang, isInTranslationMode } = args;
  const [translateTextMutation] = useTranslateTextMutation();
  const { addToTranslationCache } = useTranslationCacheContext();
  useEffect(() => {
    async function prefetchTranslations() {
      if (!obj) return;
      const allEnValues = findTsEnValues(obj);
      const res = await translateTextMutation({
        variables: {
          text: allEnValues,
          targetLanguage: lang,
        },
      });
      if (res.data?.translateText.success) {
        res.data.translateText.text.forEach((translation, i) => {
          addToTranslationCache({
            targetLang: lang,
            sourceText: allEnValues[i],
            targetText: translation,
          });
        });
      }
    }
    if (isInTranslationMode && lang !== "en") {
      prefetchTranslations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addToTranslationCache,
    isInTranslationMode,
    lang,
    // Intentionally only rerun this when the object ID changes, not when the whole object changes.
    // This is because we only want to prepopulate the cache when the object is first loaded, not when it changes.
    objId,
    translateTextMutation,
  ]);
};
export type GetTranslationReturn = {
  loading: boolean;
  text: string | undefined | null;
};
export const useTranslation = <T extends TranslationSetInput | string>(
  val: T | undefined | null,
  targetLang: Language,
  ignoreOverrides?: boolean | undefined,
): GetTranslationReturn => {
  const enText: string | null | undefined = isTranslationSet(val)
    ? val?.en
    : val;
  const { getCachedTranslation, addToTranslationCache } =
    useTranslationCacheContext();
  const [translateTextMutation] = useTranslateTextMutation({
    variables: {
      targetLanguage: targetLang,
      text: [enText || ""],
    },
    onCompleted(data) {
      if (data.translateText.success) {
        addToTranslationCache({
          targetLang: data.translateText.language,
          sourceText: enText || "",
          targetText: data.translateText.text[0],
        });
      }
    },
  });
  const { debouncedFunc } = useDebounce({
    func: translateTextMutation,
    wait: false,
    timeout: 200,
  });
  const currentCacheHit = getCachedTranslation(targetLang, enText);
  useEffect(() => {
    if (
      isNotNullOrUndefined(enText) &&
      targetLang !== "en" &&
      isNullOrUndefined(currentCacheHit)
    ) {
      debouncedFunc();
    }
  }, [currentCacheHit, targetLang, enText]); // eslint-disable-line react-hooks/exhaustive-deps
  const previousCacheHit = usePreviousNonNullOrUndefined(currentCacheHit);
  return useMemo(() => {
    if (!enText) {
      return { loading: false, text: enText };
    }
    if (targetLang === "en") {
      return { loading: false, text: enText };
    }
    if (!isTranslationSet(val)) {
      if (currentCacheHit === undefined) {
        return { loading: true, text: previousCacheHit || enText };
      }
      return { loading: false, text: currentCacheHit };
    }
    if (!ignoreOverrides) {
      const override = val.translationOverrides?.find(
        (x) => x.language === targetLang,
      );
      if (override) {
        return { loading: false, text: override.text };
      }
    }
    if (val.translationsDisabled) {
      return { loading: false, text: val.en };
    }
    if (currentCacheHit === undefined) {
      return { loading: true, text: previousCacheHit };
    }
    return { loading: false, text: currentCacheHit };
  }, [
    currentCacheHit,
    enText,
    ignoreOverrides,
    previousCacheHit,
    targetLang,
    val,
  ]);
};

const isTranslationSet = (
  val: TranslationSetInput | string | null | undefined,
): val is TranslationSetInput => {
  return val !== undefined && val !== null && typeof val !== "string";
};

export const getTextForLang = (
  ts: TranslationSetObjectInput,
  lang: Language,
): string => {
  if (lang === "en") {
    return ts.en;
  }
  const override = ts.translationOverrides?.find((x) => x.language === lang);
  if (override) {
    return override.text;
  }
  return "";
};
