import Button from "@src/deprecatedDesignSystem/components/Button";
import useClickOutside from "@hooks/useClickOutside";
import { useModal } from "@hooks/useModal";
import {
  deprecatedColors,
  deprecatedTones,
} from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { css, StyleDeclaration, StyleSheet } from "aphrodite";
import {
  CSSProperties,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useRef,
} from "react";

export type ModalButtonProps = {
  copy: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  alert?: boolean;
  "data-testid"?: string;
  style?: CSSProperties;
};

export type ConfirmButtonProps = ModalButtonProps & { loading?: boolean };

type Props = {
  showHeader?: boolean;
  showCloseBtn?: boolean;
  title?: JSX.Element | string;
  cancelButtonProps?: ModalButtonProps;
  confirmButtonProps?: ConfirmButtonProps;
  children: ReactNode;
  headerClassName?: string;
  footerClassName?: string;
  titleClassName?: string;
  className?: string;
  onBack?: () => void;
  backText?: string;
  fullscreen?: boolean;
  subFooterContent?: ReactElement;
  style?: CSSProperties;
  bodyStyle?: CSSProperties;
  closeOnClickOutside?: boolean;
  styleDeclaration?: StyleDeclaration;
  customFooter?: ReactElement;
  primaryFooterContainerStyleDeclaration?: StyleDeclaration;
};

export const Modal: FC<Props> = ({
  title,
  confirmButtonProps,
  children,
  cancelButtonProps,
  headerClassName,
  titleClassName,
  className,
  footerClassName,
  onBack,
  backText = "Back",
  showHeader = true,
  showCloseBtn = true,
  fullscreen = false,
  subFooterContent,
  style,
  bodyStyle,
  closeOnClickOutside = false,
  styleDeclaration,
  customFooter,
  primaryFooterContainerStyleDeclaration,
}) => {
  const { closeModal } = useModal();
  const ref = useRef(null);
  const onClickOutside = useCallback(() => {
    if (closeOnClickOutside) {
      closeModal();
    }
  }, [closeOnClickOutside, closeModal]);
  useClickOutside(ref, onClickOutside);
  return (
    <div
      className={`${css(
        styles.container,
        styleDeclaration && styleDeclaration,
        fullscreen && styles.fullscreen,
      )} ${className ? className : ""}`}
      ref={ref}
      style={style}
    >
      {showHeader && (
        <div
          className={`${css(styles.headerContent)} ${
            headerClassName ? headerClassName : ""
          }`}
        >
          {onBack && (
            <Button
              styleDeclaration={styles.backButton}
              variant="No Outline"
              height="40px"
              onClick={onBack}
              leftIcon="chevron-left"
              text={backText}
            />
          )}
          <div
            className={`${css(styles.title)} ${
              titleClassName ? titleClassName : ""
            }`}
          >
            {title}
          </div>
          <div>
            {showCloseBtn && (
              <Button
                variant="No Outline"
                height="40px"
                styleDeclaration={styles.closeButton}
                leftIcon="close"
                onClick={() => closeModal()}
              />
            )}
          </div>
        </div>
      )}
      <div
        style={{
          overflowY: "auto",
          height: "100%",
          ...bodyStyle,
        }}
      >
        {children}
      </div>
      {customFooter}
      {!customFooter && (confirmButtonProps || cancelButtonProps) && (
        <div
          className={`${css(styles.footer)} ${
            footerClassName ? footerClassName : ""
          }`}
        >
          {subFooterContent || <div />}
          <div
            className={css(
              styles.primaryFooterContainer,
              primaryFooterContainerStyleDeclaration,
            )}
          >
            {cancelButtonProps && (
              <Button
                variant="Outline"
                height="40px"
                onClick={cancelButtonProps.onClick}
                disabled={cancelButtonProps.disabled}
                loading={cancelButtonProps.loading}
                style={{ marginRight: 8, ...cancelButtonProps.style }}
                text={cancelButtonProps.copy}
                data-testid={cancelButtonProps["data-testid"]}
              />
            )}
            {confirmButtonProps && (
              <Button
                variant={confirmButtonProps.alert ? "Destroy" : "Primary"}
                height="40px"
                onClick={confirmButtonProps.onClick || (() => null)}
                disabled={confirmButtonProps.disabled}
                loading={confirmButtonProps.loading}
                style={{
                  width:
                    cancelButtonProps || subFooterContent ? undefined : "100%",
                  ...confirmButtonProps.style,
                }}
                text={confirmButtonProps.copy}
                data-testid={confirmButtonProps["data-testid"]}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "95vh",
    maxWidth: 902,
    minWidth: 360,
    zIndex: 700,
    opacity: 1,
    borderRadius: 16,
    backgroundColor: deprecatedColors.background,
    overflow: "hidden",
  },
  fullscreen: {
    width: "100%",
    maxWidth: 902,
    minWidth: 360,
    margin: 24,
  },
  back: {
    cursor: "pointer",
    marginBottom: 11,
    border: "none",
    background: "inherit",
    fontWeight: 400,
    fontSize: 14,
    color: deprecatedColors.outline,
    alignSelf: "flex-start",
    padding: 0,
    whiteSpace: "nowrap",
  },
  headerContent: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 56,
    padding: "12px 12px 12px 24px",
    borderBottom: `1px solid ${deprecatedTones.gray5Alpha}`,
  },
  title: {
    color: deprecatedTones.black,
    fontSize: 16,
    fontWeight: 600,
  },
  closeButton: {
    position: "absolute",
    top: "50%",
    right: 10,
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  backButton: {
    position: "absolute",
    top: "50%",
    left: 10,
    transform: "translateY(-50%)",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: `1px solid ${deprecatedTones.gray5Alpha}`,
    padding: "16px 20px 16px 16px",
    backgroundColor: deprecatedColors.background,
  },
  headerButton: {
    borderRadius: 8,
    backgroundColor: deprecatedTones.blue2,
    padding: "12px 16px",
    marginRight: 20,
    color: deprecatedColors.onPrimaryContainer,
  },
  spinner: {
    position: "absolute",
    left: "calc(50% - 8px)",
    top: "calc(50% - 8px)",
  },
  primaryFooterContainer: {
    display: "flex",
  },
});

export default Modal;
