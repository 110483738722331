import { FC, useMemo } from "react";
import { css, StyleSheet } from "aphrodite";
import { DeprecatedIconType } from "../deprecatedIcons";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Avatar from "@src/components/ui/Avatar";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import Text from "@ui/text";
import {
  DeprecatedColor,
  DeprecateTone,
  DeprecatedToneName,
  deprecatedTones,
} from "../styles/deprecatedColors";

type Props = {
  label: string;
  iconOrAvatar: DeprecatedIconType | "avatar";
  onClick?: () => void;
  color?: DeprecatedToneName;
};

type ColorValues = {
  background: DeprecateTone | DeprecatedColor;
  border: DeprecateTone | DeprecatedColor;
  label: DeprecateTone | DeprecatedColor;
  icon: DeprecateTone | DeprecatedColor;
};
const toneNameToColorValues: { [key in DeprecatedToneName]: ColorValues } = {
  blue: {
    background: deprecatedTones.blue1,
    border: deprecatedTones.blue3,
    label: deprecatedTones.blue12,
    icon: deprecatedTones.blue6,
  },
  purple: {
    background: deprecatedTones.purple1,
    border: deprecatedTones.purple3,
    label: deprecatedTones.purple12,
    icon: deprecatedTones.purple6,
  },
  gray: {
    background: deprecatedTones.gray1,
    border: deprecatedTones.gray3,
    label: deprecatedTones.gray12,
    icon: deprecatedTones.gray6,
  },
  green: {
    background: deprecatedTones.green1,
    border: deprecatedTones.green3,
    label: deprecatedTones.green12,
    icon: deprecatedTones.green6,
  },
  yellow: {
    background: deprecatedTones.yellow1,
    border: deprecatedTones.yellow3,
    label: deprecatedTones.yellow12,
    icon: deprecatedTones.yellow6,
  },
  red: {
    background: deprecatedTones.red1,
    border: deprecatedTones.red3,
    label: deprecatedTones.red12,
    icon: deprecatedTones.red6,
  },
  orange: {
    background: deprecatedTones.orange1,
    border: deprecatedTones.orange3,
    label: deprecatedTones.orange12,
    icon: deprecatedTones.orange6,
  },
};

const RoundedPill: FC<Props> = ({
  label,
  iconOrAvatar,
  onClick,
  color = "blue",
}) => {
  const colors = toneNameToColorValues[color];
  const IconOrAvatar = useMemo(() => {
    if (iconOrAvatar === "avatar") {
      return (
        <Avatar size={20} color={color} person={{ id: -1, name: label }} />
      );
    } else {
      return (
        <DeprecatedIcon type={iconOrAvatar} iconSize={20} color={colors.icon} />
      );
    }
  }, [iconOrAvatar, color, label, colors.icon]);

  return (
    <AutoLayout
      paddingLeft={2}
      height={24}
      paddingRight={onClick ? 0 : 12}
      alignmentVertical="center"
      borderRadius={16}
      className={css(styles.roundedPill)}
      style={{
        backgroundColor: colors.background,
        borderColor: colors.border,
        cursor: onClick ? "pointer" : "auto",
      }}
    >
      <AutoLayout
        alignmentVertical="center"
        spaceBetweenItems={iconOrAvatar === "avatar" ? 4 : 0}
      >
        {IconOrAvatar}
        <Text type="P2" fontWeight="Medium" color={colors.label}>
          {label}
        </Text>
      </AutoLayout>
      {onClick && (
        <DeprecatedIcon
          type="close"
          iconSize={12}
          color={colors.icon}
          onClick={onClick}
        />
      )}
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  roundedPill: {
    borderWidth: 1,
    borderStyle: "solid",
  },
});

export default RoundedPill;
