import useClickOutside from "@hooks/useClickOutside";
import { useKeystrokes } from "@hooks/useKeystrokes";
import {
  CSSProperties,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useScreenDimensions } from "@hooks/useScreenDimensions";
import useGetRefPositionAndDimensions from "@hooks/useGetRefPositionAndDimensions";

export type UseDropdownReturn = {
  anchorRef: RefObject<HTMLDivElement>;
  dropdownMenuRef: RefObject<HTMLDivElement>;
  dropdownMenuPositioningStyles: DropdownMenuPositioningStyles;
  isDropdownVisible: boolean;
  setIsDropdownVisible: (val: boolean) => void;
};

export type DropdownMenuPositioningStyles = {
  position: CSSProperties["position"];
  top: CSSProperties["top"];
  right: CSSProperties["right"];
  visibility: CSSProperties["visibility"];
};

type Args = {
  onClose?: () => void;
  dropdownOffsetX?: number;
  dropdownOffsetY?: number;
};

const useDropdown = (args?: Args): UseDropdownReturn => {
  const [visible, setVisibleInner] = useState(false);
  const { onClose, dropdownOffsetX = 0, dropdownOffsetY = 0 } = args || {};
  const anchorRef: RefObject<HTMLDivElement> = useRef(null);
  const dropdownMenuRef: RefObject<HTMLDivElement> = useRef(null);
  const dimensions = useScreenDimensions();
  const {
    x: anchorX,
    y: anchorY,
    width: anchorWidth,
    height: anchorHeight,
  } = useGetRefPositionAndDimensions(anchorRef);
  const dropdownMenuPositioningStyles: DropdownMenuPositioningStyles = useMemo(
    () => ({
      top: anchorY + anchorHeight + dropdownOffsetY,
      right: dimensions.width - (anchorX + anchorWidth - dropdownOffsetX),
      position: "fixed",
      visibility: "visible",
    }),
    [
      anchorHeight,
      anchorWidth,
      anchorX,
      anchorY,
      dimensions.width,
      dropdownOffsetX,
      dropdownOffsetY,
    ],
  );
  const clickOutsideCallback = useCallback(() => {
    if (visible) {
      setVisibleInner(false);
      onClose && onClose();
    }
  }, [visible, onClose]);
  const { disableClickOutside } = useClickOutside(
    dropdownMenuRef,
    clickOutsideCallback,
  );
  const setVisible = useCallback(
    (val: boolean) => {
      if (val) {
        disableClickOutside();
      }
      setVisibleInner(val);
    },
    [disableClickOutside],
  );

  const keyStrokeConfig = useMemo(() => {
    return {
      Escape: () => {
        if (visible) {
          setVisible(false);
        }
      },
    };
  }, [visible, setVisible]);
  useKeystrokes(keyStrokeConfig);
  return useMemo(
    () => ({
      anchorRef,
      dropdownMenuRef,
      isDropdownVisible: visible,
      setIsDropdownVisible: setVisible,
      dropdownMenuPositioningStyles,
    }),
    [dropdownMenuPositioningStyles, setVisible, visible],
  );
};

export default useDropdown;
