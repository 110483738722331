import { FC, ReactNode } from "react";
import { css, StyleSheet } from "aphrodite";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import { deprecatedTones } from "../styles/deprecatedColors";

type Props = {
  width?: number;
  showIndent?: boolean;
  children: ReactNode | ReactNode[];
};

const IndentedLeftBorder: FC<Props> = (props) => {
  return (
    <AutoLayout
      paddingLeft={props.showIndent ? 16 : 0}
      alignSelf="stretch"
      spaceBetweenItems={12}
    >
      {props.showIndent && (
        <AutoLayout
          alignSelf="stretch"
          marginBottom={16}
          width={props.width ? props.width : 32}
          className={css(styles.leftBorder)}
        />
      )}
      <AutoLayout
        flex={1}
        alignSelf="stretch"
        direction="vertical"
        spaceBetweenItems={props.showIndent ? 4 : 12}
      >
        {props.children}
      </AutoLayout>
    </AutoLayout>
  );
};

const styles = StyleSheet.create({
  leftBorder: {
    borderBottomLeftRadius: 12,
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: deprecatedTones.gray5,
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: deprecatedTones.gray5,
  },
});

export default IndentedLeftBorder;
