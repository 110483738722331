import { useState, useEffect } from "react";
import { getMuxVideoId, getLinkType, LinkType } from "@utils/files";

export const useMuxPreviewUrl = (link: string): string | undefined => {
  const [url, setUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getUrl = async () => {
      if (getLinkType(link) !== LinkType.MUX_VIDEO) {
        return;
      }
      const muxUrl = `https://image.mux.com/${getMuxVideoId(
        link,
      )}/animated.gif`;
      try {
        const res = await fetch(muxUrl, {
          method: "GET",
        });
        if (res.ok) {
          setUrl(muxUrl);
        }
      } catch {
        setUrl(undefined);
      }
    };
    getUrl();
  }, [link]);

  return url;
};
