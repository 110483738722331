import Text from "@ui/text";
import UpgradeButton from "@src/components/settings/billing/UpgradeButton";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import DeprecatedIcon from "@src/deprecatedDesignSystem/components/DeprecatedIcon";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { StyleSheet } from "aphrodite";
import { FC } from "react";

type Props = {
  description: string;
};

const UpgradePlanCTA: FC<Props> = ({ description }) => {
  return (
    <AutoLayout
      styleDeclaration={styles.upgradeContainer}
      spaceBetweenItems={12}
      marginTop={12}
    >
      <AutoLayout
        minWidth={32}
        flex={1}
        alignSelf="stretch"
        style={{ backgroundColor: deprecatedTones.yellow2, flexShrink: 0 }}
        borderRadius={8}
        height={32}
        width={32}
        alignmentHorizontal="center"
        alignmentVertical="center"
      >
        <DeprecatedIcon
          type="lock"
          color={deprecatedTones.yellow10}
          iconSize={24}
        />
      </AutoLayout>
      <AutoLayout direction="vertical">
        <Text marginBottom={8} type="P1" fontWeight="SemiBold" multiline>
          Upgrade your plan
        </Text>
        <Text color={deprecatedTones.gray10} type="P2" multiline>
          {description}
        </Text>
        <UpgradeButton className="mt-3" />
      </AutoLayout>
    </AutoLayout>
  );
};
const styles = StyleSheet.create({
  upgradeContainer: {
    maxWidth: 400,
    borderRadius: 8,
    backgroundColor: deprecatedTones.yellow1,
    padding: 16,
  },
});

export default UpgradePlanCTA;
