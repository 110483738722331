import {
  CourseValidationErrors,
  PromptErrorsArray,
} from "@src/components/libraryItemDetailPages/course/types/validation";
import { AiRequestFragment } from "@src/components/libraryItemDetailPages/course/hooks/useCourseVersionAIRequests.generated";
import { useMemo } from "react";
import {
  CourseContentLessonFragment,
  CourseContentPromptFragment,
} from "../build/CourseBuildContent.generated";
import { CommentThreadFragment } from "../build/comments/Comment.generated";
import { Atom, atom } from "jotai";
import { courseVersionLessonsAtom } from "../atoms";
import { selectAtom } from "jotai/utils";
import { isEqual } from "lodash";

type BaseScreen = {
  id: string;
  index: number;
};

export type PromptScreenAttrs = {
  prompt: CourseContentPromptFragment;
  lesson: CourseContentLessonFragment;
  promptPositionInLesson: number;
  validationErrors: PromptErrorsArray;
  isGeneratedByAI: boolean;
  commentThread: CommentThreadFragment | undefined;
  aiRequests: AiRequestFragment[] | undefined;
};

export type PromptScreen = BaseScreen & {
  promptScreen: PromptScreenAttrs;
};

export type LessonScreenAttrs = {
  lesson: CourseContentLessonFragment;
  index: number;
  hasEmptyLessonValidationErrors: boolean;
};

export type LessonScreen = BaseScreen & {
  lessonScreen: LessonScreenAttrs;
};

export type HomeScreen = BaseScreen & {
  // eslint-disable-next-line @typescript-eslint/ban-types
  homeScreen: {};
};

export type CourseScreen = PromptScreen | LessonScreen | HomeScreen;

type Return = {
  screensAtom: Atom<CourseScreen[]>;
  screensMapAtom: Atom<Map<string, CourseScreen>>;
};

const useCourseScreensAtom = (
  validationErrorsAtom?: Atom<CourseValidationErrors | undefined>,
  promptUuidsGeneratedByAI?: Set<string>,
  commentThreads?: CommentThreadFragment[] | undefined,
  aiRequests?: AiRequestFragment[] | undefined,
): Return => {
  const courseScreensAtom = useMemo(
    () =>
      atom((get) => {
        const validationErrors = validationErrorsAtom
          ? get(validationErrorsAtom)
          : undefined;
        const promptUuidToCommentThreadMap = new Map<
          string,
          CommentThreadFragment
        >();
        commentThreads?.forEach((ct) => {
          promptUuidToCommentThreadMap.set(ct.prompt.uuid, ct);
        });
        const promptUuidToAIRequestsMap = new Map<
          string,
          AiRequestFragment[]
        >();
        aiRequests?.forEach((aiReq) => {
          if (aiReq.aiEditPromptRequest?.prompt) {
            const uuid = aiReq.aiEditPromptRequest.prompt.uuid;
            const array = promptUuidToAIRequestsMap.get(uuid) || [];
            array.push(aiReq);
            promptUuidToAIRequestsMap.set(uuid, array);
          }
        });
        const screens: CourseScreen[] = [];
        const screensMap = new Map<string, CourseScreen>();
        const courseVersionLessons = get(courseVersionLessonsAtom);
        if (!courseVersionLessons) return { screens, screensMap };
        const homeScreen: CourseScreen = {
          index: 0,
          id: getCourseHomeScreenId(),
          homeScreen: {},
        };
        screens.push(homeScreen);
        screensMap.set(homeScreen.id, homeScreen);
        let index = 1;
        for (
          let lessonIdx = 0;
          lessonIdx < courseVersionLessons.length;
          lessonIdx++
        ) {
          const lesson = courseVersionLessons[lessonIdx];
          if (courseVersionLessons.length > 1) {
            const lessonScreen: CourseScreen = {
              index: index,
              id: getLessonScreenId(lesson.uuid),
              lessonScreen: {
                lesson,
                index: lessonIdx,
                hasEmptyLessonValidationErrors:
                  !!validationErrors?.emptyLessons.has(lesson.uuid),
              },
            };
            screens.push(lessonScreen);
            screensMap.set(lessonScreen.id, lessonScreen);
            index++;
          }
          for (
            let promptIdx = 0;
            promptIdx < lesson.prompts.length;
            promptIdx++
          ) {
            const prompt = lesson.prompts[promptIdx];
            const promptScreen: CourseScreen = {
              index: index,
              id: getPromptScreenId(prompt.uuid),
              promptScreen: {
                prompt,
                lesson,
                validationErrors:
                  validationErrors?.promptErrors.get(prompt.uuid) || [],
                isGeneratedByAI:
                  promptUuidsGeneratedByAI?.has(prompt.uuid) || false,
                commentThread: promptUuidToCommentThreadMap.get(prompt.uuid),
                aiRequests: promptUuidToAIRequestsMap.get(prompt.uuid),
                promptPositionInLesson: promptIdx,
              },
            };
            screens.push(promptScreen);
            screensMap.set(promptScreen.id, promptScreen);
            index++;
          }
        }
        return { screens, screensMap };
      }),
    [
      aiRequests,
      commentThreads,
      promptUuidsGeneratedByAI,
      validationErrorsAtom,
    ],
  );
  const screensAtom = useMemo(
    () => selectAtom(courseScreensAtom, (val) => val?.screens, isEqual),
    [courseScreensAtom],
  );
  const screensMapAtom = useMemo(
    () => selectAtom(courseScreensAtom, (val) => val?.screensMap, isEqual),
    [courseScreensAtom],
  );

  return { screensAtom, screensMapAtom };
};

export default useCourseScreensAtom;

export const getCourseHomeScreenId = (): string => "course-home";
export const getLessonScreenId = (lessonUuid: string): string =>
  `lesson-${lessonUuid}`;
export const getPromptScreenId = (promptUuid: string): string =>
  `prompt-${promptUuid}`;
