import { useMemo } from "react";
import gql from "graphql-tag";
import {
  RoleGroupThinFragment,
  useGetRoleGroupsQuery,
} from "./useRoleGroups.generated";

type Return = {
  roleGroups: RoleGroupThinFragment[];
  loading: boolean;
};

const useRoleGroups = (): Return => {
  const { data, previousData, loading } = useGetRoleGroupsQuery();
  const roleGroups = useMemo(() => {
    const d = data || previousData;
    return d?.RoleGroups.objects || [];
  }, [data, previousData]);

  return useMemo(
    () => ({
      roleGroups,
      loading,
    }),
    [roleGroups, loading],
  );
};

gql`
  query GetRoleGroups {
    RoleGroups {
      totalCount
      objects {
        ...RoleGroupThin
      }
    }
  }
  fragment RoleGroupThin on RoleGroup {
    id
    name
  }
`;

export default useRoleGroups;
